import { createReducer, on } from "@ngrx/store";
import { setCourseId } from "../actions/courseChange.actions";

export interface CourseState {
  courseId: string | null;
}

export const initialState: CourseState = {
  courseId: null,
};

const _courseReducer = createReducer(
  initialState,
  on(setCourseId, (state, { courseId }) => ({ ...state, courseId }))
);

export function courseReducer(state, action) {
  return _courseReducer(state, action);
}
