import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private userRole: string[] = []; // Initialize with the default role
  current_user
  constructor(private store: LocalStorageService,private authService : AuthService,
              private router: Router) { 
    this.current_user = JSON.parse(this.store.getItem('user'))
    if(!this.current_user){
      router.navigate(['login'])
    }
    if(this.current_user){
      if(this.current_user.roles !=='admin' && this.current_user.roles !=='student'){
        let roles =JSON.parse(this.store.getItem('empType'))
        this.userRole = roles.map(item=>item.displayname)
      }
      if(this.current_user.roles ==='admin'){
        let user =  JSON.parse(this.store.getItem('user'))
        this.userRole.push(user.roles)
      }
    }
    
    
  }


  setUserRole(role: string[]): void {
    this.userRole = role;
  }

  getUserRole(): string[] {
    return this.userRole;
  }

  hasRole(role: string[]): boolean {
    if(role){
      return this.userRole.map(item=>item.toLowerCase()).some(item => role.map(item=>item.toLowerCase()).includes(item));
    }
   
  }
}
