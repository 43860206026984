import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { ADMINROUTES, CLIENTROUTES, EMPLOYEEROUTES, EXTRACTORROUTES, STUDENTROUTES } from "../horizontal-menu/navigation-routes.config";
import { STUDENTROUTESS } from "../vertical-menu/vertical-menu-routes.config";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/meander.png';
  shortLogoUrl = 'assets/img/small_logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  user:any
  CLIENTROUTES :any=[]
  empType =[]
  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,private apiService:ApiService,
  ) {
    let userObject=JSON.parse(localStorage.getItem('user'))
    this.user = userObject
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.menuItems = STUDENTROUTESS;
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        let userObject = JSON.parse(localStorage.getItem('user'))
        switch (userObject.roles) {
          case 'admin':
            this.menuItems = ADMINROUTES;
            break;
          case 'extractor':
            this.menuItems = EXTRACTORROUTES;
            break;
          case 'student':
            this.menuItems = STUDENTROUTESS;
            break;
          case 'client':
            this.menuItems = CLIENTROUTES;
            break;
          case 'employee':
            this.menuItems = EMPLOYEEROUTES;
            let emp_type
            emp_type = userObject.employeetype.map(item=>item._id)
            this.apiService.getMainMenuByEmployeeTypeId(emp_type).subscribe((res:any)=>{
              this.CLIENTROUTES = res?.data
              // const updatedArray = this.CLIENTROUTES.map(obj => {
              //   const { classes, ...rest } = obj;
              //   return {
              //     ...rest,
              //     class: classes
              //   };
              // });
             let updatedArray= this.updateKey(this.CLIENTROUTES, 'classes', 'class');
      
              // console.log("updatedArray>>>>>>>>>>>",updatedArray)
              this.menuItems = updatedArray;
      
      
              const desiredStatusOrder = ['Dashboard', 'HRM', 'CRM', 'Work','Training','Utility','Tools'];
      
              // Sort the items in descending order based on their status property
              this.menuItems.sort((a, b) => {
                const aIndex = desiredStatusOrder.indexOf(a.title);
                const bIndex = desiredStatusOrder.indexOf(b.title);
                return aIndex - bIndex;
              });
              // if(this.menuItems[0]?.title !=='Dashboard'){
              //   this.menuItems.reverse()
              // }
            })
            break;
        }
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/meander.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }
 
  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }
  updateKey(obj: any, oldKey: string, newKey: string): any {
    if (obj instanceof Array) {
      return obj.map(item => this.updateKey(item, oldKey, newKey));
    } else if (typeof obj === 'object') {
      const updatedObj: any = {};
      for (const key in obj) {
        if (key === oldKey) {
          updatedObj[newKey] = obj[key];
        } else {
          updatedObj[key] = this.updateKey(obj[key], oldKey, newKey);
        }
      }
      return updatedObj;
    }
    return obj;
  }

}
