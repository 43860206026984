import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
  // transform(fullName: string): any {
  //   return fullName?.split(' ').map((n) => n[0]).join('');
  // }
  transform(fullName: string): any {
    // if (!fullName) {
    //     return '';
    // }
    // const nameParts = fullName.trim().split(/\s+/);
    // let initials = '';
    // if (nameParts.length > 0) {
    //     initials += nameParts[0][0];
    // }
    // if (nameParts.length > 1) {
    //     initials += nameParts[1][0];
    // }
    // return initials;

    // if (!fullName) {
    //   return '';
    // }
    // const nameParts = fullName.trim().split(/\s+/);
    // let initials = '';
    // for (const part of nameParts) {
    //   if (part.match(/^[a-zA-Z]/)) {
    //     initials += part[0].toUpperCase();
    //   }
    // }
    // return initials;
    if (!fullName) {
      return '';
    }

    const nameParts = fullName.trim().split(/\s+/);
    let initials = '';
    
    // Loop through all name parts (words)
    for (let i = 0; i < nameParts.length; i++) {
      if (nameParts[i].match(/^[a-zA-Z]/)) {
        initials += nameParts[i][0].toUpperCase();
      }

      // Stop after extracting the first letter of three words
      if (i === 2) {
        break;
      }
    }

    return initials;
}
}
