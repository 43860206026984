import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ADMINROUTES,  EMPLOYEEROUTES, EXTRACTORROUTES, STUDENTROUTES } from './navigation-routes.config';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})

export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  public menuItems: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = 'Top';

  layoutSub: Subscription;
  userId:any;
  role:any;
  user:any
  CLIENTROUTES:any=[]
  constructor(private layoutService: LayoutService,
    private apiService:ApiService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private router: Router) {
    this.config = this.configService.templateConf;
    let userObject=JSON.parse(localStorage.getItem('user'))
    this.user = userObject
    this.userId = userObject.id
    
switch(userObject.roles){
  case 'admin':
    this.menuItems = ADMINROUTES;
    break;
    case 'extractor':
      this.menuItems = EXTRACTORROUTES;
      break;
    case 'viewer':
      this.menuItems = EXTRACTORROUTES;
      break;
    case 'student':
    this.menuItems = STUDENTROUTES;
    break;
    case 'employee':
      this.menuItems = EMPLOYEEROUTES;
      let emp_type = userObject.employeetype.map(item=>item._id)
      apiService.getMainMenuByEmployeeTypeId(emp_type).subscribe((res:any)=>{
      this.CLIENTROUTES = res?.data
      let updatedArray= this.updateKey(this.CLIENTROUTES, 'classes', 'class');
      this.menuItems = updatedArray;


        const desiredStatusOrder = ['Dashboard', 'HRM', 'CRM', 'Work','Training','Utility','Tools'];

        // Sort the items in descending order based on their status property
        this.menuItems.sort((a, b) => {
          const aIndex = desiredStatusOrder.indexOf(a.title);
          const bIndex = desiredStatusOrder.indexOf(b.title);
          return aIndex - bIndex;
        });
        // if(this.menuItems[0]?.title !=='Dashboard'){
        //   this.menuItems.reverse()
        // }
      })
      break;
   
} 
  }
  updateKey(obj: any, oldKey: string, newKey: string): any {
    if (obj instanceof Array) {
      return obj.map(item => this.updateKey(item, oldKey, newKey));
    } else if (typeof obj === 'object') {
      const updatedObj: any = {};
      for (const key in obj) {
        if (key === oldKey) {
          updatedObj[newKey] = obj[key];
        } else {
          updatedObj[key] = this.updateKey(obj[key], oldKey, newKey);
        }
      }
      return updatedObj;
    }
    return obj;
  }
  ngOnInit() {
    this.getCurrentUser()
  }
  getCurrentUser(){
    this.apiService.getUserbyId(this.userId).subscribe((res:any)=>{
      this.role=res?.data?.roles
    })
  }

  ngAfterViewInit() {

    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }


    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

}
