import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();

@Injectable({
  providedIn: 'root'
})
export class TawkToService {

  private loaded: boolean;
  private loadSubject: Subject<boolean> = new Subject<boolean>();
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    private LocalStorageService: LocalStorageService) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.load();
  }

  private load() {
    if (this.loaded)
      return;

    const s = this.renderer.createElement('script');
    const s0 = document.getElementsByTagName("script")[0];
    s.type = 'text/javascript';
    s.src = 'https://embed.tawk.to/6130b240d6e7610a49b346a0/1fej3gk2r';
    s.async = true;
    s.charset = 'UTF-8';
    s0.parentNode.insertBefore(s, s0);
    ;
    this.renderer.appendChild(this._document.body, s);
    Tawk_API.onLoad = this.loadedEvent.bind(this);
  }

  private loadedEvent() {
    this.loaded = true;
    this.loadSubject.next(this.loaded);
  }

  public UpdateTawkUser(user: any) {
    this.loadedWrapper(() => { this.updateAtrributes(user) });
  }

  private loadedWrapper(func: any) {
    if (!this.loaded) {
      var sub = this.loadSubject.asObservable().subscribe({
        next: () => {
          func();
          sub.unsubscribe();
        },
        error: () => { }
      });
    } else {
      func();
    }
  }

  public ExpandChatWindow(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.maximize() : Tawk_API.minimize());
  }

  public SetChatVisibility(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.showWidget() : Tawk_API.hideWidget());
  }

  private updateAtrributes(user: any) {
    Tawk_API.setAttributes({
      'name': `${user.firstname} ${user.surname}`,
      'email': user.email,
      'id': user.id,
    }, function (error) { });
  }
}