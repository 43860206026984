
import { Component, ViewEncapsulation, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})

export class NgbdModalContent {
  @Input() public user;
  @Input() public enrollId;
  @Input() public enrolementStatusId;
  @Input() public activeTab;
  @Input() public enrol_Id;
  @Input() public transaction_id;
  @Input() public activatedData
  @Input() public enrolementStatus_Id;
  @Input() public activated_Data
  @Input() public completedCourse
  @Input() public dueFees
  @ViewChild('vform') validationForm: FormGroup;
  isActivated: boolean
  isShow: boolean = false;
  isBatch: boolean = false
  isCollege: boolean = false
  isEnrollmentPopup: boolean = false;
  isEnrolmentStatus: boolean = false
  isTransaction: boolean = false
  isPayButton: boolean = false
  isTransactionUpdate: boolean = false
  isEnrolment_completedCourse: boolean = false
  isDueFees: boolean = false
  tagsForm: FormGroup;
  fileData: File;
  selectedValue: string[] = [];
  categories = []
  tagsFormSubmitted: boolean = false;
  formBuilder: any;
  apiService: any;
  toastr: any;
  route: any;
  modalService: any;
  enrolmentData: any
  transactionData: any
  duePayment: any
  duePending: any
  paymentModeId = '631ada2ace5c666542591bf6'
  paymentMode = []
  constructor(public activeModal: NgbActiveModal,
  ) {

  }
  ngOnInit() {
    // console.log('enrollId',this.enrollId)
    // console.log('due',this.dueFees)
    // console.log('==',this.user)
    // console.log('==>> enrolementStatusId',this.enrolementStatusId)
    // console.log('enrol_id',this.enrol_Id ,'transaction_id',this.transaction_id)
    // console.log('activatedData',this.activatedData)
    // console.log('== enrolementStatus_Id',this.enrolementStatus_Id)
    // console.log('activated_Data',this.activated_Data)
    // console.log('activeTab',this.activeTab)
    // console.log('==>>> completedCourse',this.completedCourse)

    if (this.user == 'college') {
      this.isCollege = true
      this.isBatch = false
    }
    else if (this.user == 'batch') {
      this.isBatch = true
      this.isCollege = false
    }
    else if (this.enrolementStatusId && this.activatedData) {
      this.isEnrolmentStatus = true
      this.enrolmentGetById(this.enrolementStatusId);
    }
    else if (this.enrolementStatus_Id && this.activated_Data && this.completedCourse == 'Yes') {
      this.isEnrolment_completedCourse = true
      this.isEnrolmentStatus = false
      this.enrolmentGetById(this.enrolementStatus_Id);
    }
    else if (this.enrol_Id && this.transaction_id) {
      this.isTransactionUpdate = true
      this.enrolmentGetById(this.enrol_Id)
    }
    else if (this.enrollId) {
      this.isEnrollmentPopup = true
      this.enrolmentGetById(this.enrollId);
      this.paymentModeList()
    }
    else if (this.dueFees == 'total due fee') {
      this.isDueFees = true
      this.apiService.getPaymentDue(1, 10).subscribe((res: any) => {
        console.log('payment Due ', res)
        this.duePayment = res?.data?.items
      })
      this.apiService.getPaymentPending(1, 10).subscribe((res: any) => {
        console.log('payment pending ', res)
        this.duePending = res?.data?.items

      })
    }

    this.getCategoriesList()
    this.tagsForm = this.formBuilder.group({
      name: ['', Validators.required],
      displayname: ['', Validators.required],
      description: ['', Validators.required],
      tagcategories_ids: ['', Validators.required],
      notes: ['', Validators.required],
      admin_notes: ['', Validators.required],
      image: ['', Validators.required],
    })
  }
  show() {
    this.isShow = true
  }
  getCategoryId(event: any) {
    console.log('selected category id', this.selectedValue)

  }
  paymentModeList() {
    this.apiService.getTagByTagCatgoryId(this.paymentModeId).subscribe((res: any) => {
      console.log('payment mode List', res)
      this.paymentMode = res.data
    })
  }
  // ------------------------ EnrolmentStatus  update -------------------------------------------------
  submitNotes() {
    let body = {
      notes: this.enrolmentData.notes,
      status: 'cancel'

    }
    this.apiService.updateEnrolment(this.enrolementStatusId, body).subscribe((res: any) => {
      if (res.isSuccess === true) {
        this.close()
        this.toastr.success(res.message)
        this.activeModal.close('success');
      }
    })
  }

  getCategoriesList() {
    this.apiService.getAllCategory(1, 100).subscribe((res: any) => {
      this.categories = res.data.items
    })
  }
  enrolmentGetById(id) {
    this.apiService.getEnrolmentById(id).subscribe((res: any) => {
      this.enrolmentData = res.data[0]
      // this.userId=this.enrolmentData?.user_id?._id
      // if(this.userId){
      //   this.getTransactionByUser()
      // }
    })
  }

  get rf() {
    return this.tagsForm.controls;
  }

  submit() {
    let formData = new FormData()
    // for(let item of this.selectedValue){
    //   console.log('selected Tag Type',item)
    formData.append('tagcategories_ids[]', '6319d2985ab9e462a96cfae0')
    // }
    formData.append('name', this.tagsForm.controls.name.value),
      formData.append('displayname', this.tagsForm.controls.displayname.value),
      formData.append('description', this.tagsForm.controls.description.value),
      formData.append('notes', this.tagsForm.controls.notes.value),
      formData.append('admin_notes', this.tagsForm.controls.admin_notes.value),
      formData.append('image', this.fileData)
    this.apiService.tagCreate(formData).subscribe((res: any) => {
      if (res.statusCode === 200) {
        console.log('tag create res', res)
        this.toastr.success(res.message)
        this.activeModal.close('success');
      }
      else { this.toastr.error(res.error) }
    })
    this.tagsFormSubmitted = true;
    if (this.tagsForm.invalid) {
      return;
    }
  }

  close() {
    this.modalService.dismissAll()

  }


}


@Component({
  selector: 'app-modals',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PopupModalComponent {
  @Input() public user;
  @Input() public enrollId;
  @Input() public enrolementStatusId;
  @Input() public activeTab;
  @Input() public enrol_Id;
  @Input() public transaction_id;
  @Input() public activatedData
  @Input() public activated_Data
  @Input() public enrolementStatus_Id;
  @Input() public completedCourse

  isEnrollmentPopup: boolean = false;
  isShow: boolean = false;
  isActivated: boolean
  isTransaction: boolean = false
  isPayButton: boolean = false
  isEnrolmentStatus: boolean = false
  isTransactionUpdate: boolean = false
  isEnrolment_completedCourse: boolean = false
  closeResult: string;
  businessForm: FormGroup;
  fileData: File
  enrolmentData: any
  enrolmentData1: any
  enrolmentModel = {
    paid_amount: '',
    student: '',
    entity_id: '',
    course_id: '',
    payment_status: '',
    next_payment_date: "",
    net_amount: '',
    pendingAmount: '',
    total_amount: ''
  }
  enrolmentId: any
  businessFormSubmitted: boolean = false;
  min_next_payment_date: any
  duePayment: any
  duePending: any
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public apiService: ApiService,
    private activatedroute: ActivatedRoute,
    private route: Router) {
    this.activatedroute.paramMap.subscribe(params => {
      this.enrolmentId = params.get('id');
    });
    // console.log('==',this.user)
    // console.log('enrollId==',this.enrollId)
    // console.log('== enrolementStatusId',this.enrolementStatusId)
    // console.log('activatedData',this.activatedData)
    // console.log('== enrolementStatus_Id',this.enrolementStatus_Id)
    // console.log('activated_Data',this.activated_Data)
    // console.log('activeTab',this.activeTab)
    // console.log('==>>> completedCourse',this.completedCourse)
  }

  // Open default modal
  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // This function is used in open
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  // Open modal with dark section
  openModal(customContent) {
    this.modalService.open(customContent, { windowClass: 'dark-modal' });
  }

  // Open content with dark section
  openContent() {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.name = 'World';
  }
  @ViewChild('vform') validationForm: FormGroup;

  tagsForm: FormGroup;
  selectedValue: string[] = [];
  categories = []
  tagsFormSubmitted: boolean = false;
  isBatch: boolean = false
  isCollege: boolean = false
  isDueFees: boolean = false
  paymentMode = []
  selectedPaymentMode = null
  paymentModeId = '631ada2ace5c666542591bf6'
  userId: any
  transactionData: any
  paymentData: any
  selectPaidAmount: any
  selectedPaymentNotes: any
  transactionId: any
  pamentStatusList = [
    { name: 'received', label: 'Payment Received' },
    { name: 'due', label: 'Payement Due' },
  ]
  selectedPaymentStatus = 'due'
  ngOnInit() {
    this.min_next_payment_date = new Date().toISOString().slice(0, 10);

    if (this.user == 'college') {
      this.isCollege = true
      this.isBatch = false
    }
    else if (this.user == 'batch') {
      this.isBatch = true
      this.isCollege = false
    }
    if (this.enrollId) {
      this.isEnrollmentPopup = true
      this.enrolmentGetById(this.enrollId);
      this.paymentModeList()
    }
    else if (this.enrolementStatusId && this.activatedData) {
      this.isEnrolmentStatus = true
      this.isEnrolment_completedCourse = false
      this.enrolmentGetById(this.enrolementStatusId);
    }
    else if (this.enrolementStatus_Id && this.activated_Data && this.completedCourse == 'Yes') {
      this.isEnrolment_completedCourse = true
      this.isEnrolmentStatus = false
      this.enrolmentGetById(this.enrolementStatus_Id);
    }
    else if (this.enrol_Id && this.transaction_id) {
      this.isTransactionUpdate = true
      this.enrolmentGetById(this.enrol_Id)
      this.paymentModeList()
    }
    this.getCategoriesList();
    this.tagsForm = this.formBuilder.group({
      name: ['', Validators.required],
      displayname: ['', Validators.required],
      description: ['', Validators.required],
      tagcategories_ids: ['', Validators.required],
      notes: ['', Validators.required],
      admin_notes: ['', Validators.required],
      image: ['', Validators.required],
    })
    this.businessForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      website: ['', Validators.required],
      phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      address: ['', Validators.required],
      notes: ['', Validators.required],
      type: ['',],

    })
  }
  show() {
    this.isShow = true
  }
  getCategoryId(event: any) {
    this.selectedValue

  }
  getCategoriesList() {
    this.apiService.getAllCategory(1, 100).subscribe((res: any) => {
      this.categories = res.data.items
    })
  }

  get rf() {
    return this.tagsForm.controls;
  }
  submit() {
    let formData = new FormData()
    // for(let item of this.selectedValue){
    //   console.log('selected Tag Type',item)
    formData.append('tagcategories_ids[]', '6319d2985ab9e462a96cfae0')
    // }
    formData.append('name', this.tagsForm.controls.name.value),
      formData.append('displayname', this.tagsForm.controls.displayname.value),
      formData.append('description', this.tagsForm.controls.description.value),
      formData.append('notes', this.tagsForm.controls.notes.value),
      formData.append('admin_notes', this.tagsForm.controls.admin_notes.value),
      formData.append('image', this.fileData)
    this.apiService.tagCreate(formData).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.toastr.success(res.message)
        this.activeModal.close('success');
      }
      else { this.toastr.error(res.error) }
    })
    this.tagsFormSubmitted = true;
    if (this.tagsForm.invalid) {
      return;
    }
  }
  get rff() {
    return this.businessForm.controls;
  }
  submitBusiness() {
    this.businessForm.value.type = '6325cdefbc4b543c7a2429a4'
    this.apiService.createBusiness(this.businessForm.value).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.toastr.success(res.message)
        this.activeModal.close('success');
      }
    })
    this.businessFormSubmitted = true;
    if (this.businessForm.invalid) {
      return;
    }
  }

  close() {
    this.modalService.dismissAll()
  }
  enrolmentGetById(id) {
    this.apiService.getEnrolmentById(id).subscribe((res: any) => {
      this.enrolmentData = res.data[0]
      if (res?.data[0]?._id) {
        this.apiService.getTransactionByEnrollId(res?.data[0]?._id).subscribe((res: any) => {
          this.transactionData = res.data[0]
        })
      }

      // this.userId=this.enrolmentData?.user_id?._id
      if (this.transaction_id) {
        this.getTransactionByIdd(this.transaction_id)
      }
    })
  }
  getTransactionByIdd(id) {
    this.apiService.getTransactionById(id).subscribe((res: any) => {
      if (this.enrol_Id && this.transaction_id && this.isTransactionUpdate === true) {
        this.paymentData = res?.data
        console.log('selected trans',res?.data)
        // this.transactionId=this.paymentData._id
        this.selectedPaymentNotes = this.paymentData.payment_notes
        this.selectPaidAmount = this.paymentData.paid_amount
        this.selectedPaymentMode = this.paymentData.mode._id
        this.selectedPaymentStatus = this.paymentData.payment_status
      }
      this.transactionData = res.data?.mode?.displayname
    })
  }
  transaction() {
    this.isPayButton = true
    this.isEnrollmentPopup = true
    this.isTransaction = true
  }
  paymentModeList() {
    this.apiService.getTagByTagCatgoryId(this.paymentModeId).subscribe((res: any) => {
      this.paymentMode = res.data
    })
  }
  getPaymentModeId(event: any) {
    this.selectedPaymentMode
  }
  paymentupdate() {
    let pendingFees = this.enrolmentData.pending_fee - Number(this.enrolmentModel.paid_amount)
    let body = {
      paid_amount: this.selectPaidAmount,
      student: this.enrolmentData?.user_id?._id,
      entity_id: this.enrolmentData?._id,
      course_id: this.enrolmentData?.course_id?._id,
      net_amount: this.enrolmentData?.net_fees,
      pendingAmount: pendingFees,
      total_amount: this.enrolmentData?.total_amount,
      payment_status: this.selectedPaymentStatus,
      mode: this.selectedPaymentMode,
      payment_notes: this.enrolmentModel?.next_payment_date,
    }
    if (this.transaction_id) {
      this.apiService.updateTransaction(this.transaction_id, body).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.toastr.success('Transaction update successfully')
          this.activeModal.close('success');
          this.close()
        }
        else {
          // this.toastr.error(res.error)
        }
      })
    }
  }
  paymentSubmit() {
    let pendingFees = this.enrolmentData.pending_fee - Number(this.enrolmentModel.paid_amount)
    let body = {
      paid_amount: this.enrolmentModel?.paid_amount,
      student: this.enrolmentData?.user_id?._id,
      entity_id: this.enrolmentData?._id,
      course_id: this.enrolmentData?.course_id?._id,
      net_amount: this.enrolmentData.net_fees,
      pendingAmount: pendingFees,
      total_amount: this.enrolmentData?.total_amount,
      payment_status: this.selectedPaymentStatus,
      mode: this.selectedPaymentMode,
      payment_notes: this.selectedPaymentNotes,
      next_payment_date: this.enrolmentModel?.next_payment_date
    }
    this.apiService.createTransaction(body).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.apiService.updateEnrolment(this.enrollId, body).subscribe((res: any) => {
          if (res.isSuccess === true) {
          }
        })
        this.toastr.success(res.message)
        this.activeModal.close(res);
      }
      // else {
      //  this.toastr.error(res.error)
      // }
    })
  }
  // submitEnrollment() {  
  //   let body = {
  //     next_payment_date:this.enrolmentModel.next_payment_date
  //   }
  //   console.log('body', body)
  //   if (this.enrolmentData._id) {
  //     this.apiService.updateEnrolment(this.enrolmentData._id, body).subscribe((res: any) => {   
  //       console.log('update res=>> ', res)    
  //       if (res.isSuccess === true) {
  //         console.log('update res=>> ', res)        
  //       }        
  //     })
  //   }     
  // }
  disableUser(id) {
    this.apiService.userDisableById(id).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.toastr.success('student disable successfully!')
      }
      else {
        this.toastr.error(res.error)
      }
    })
  }
  // ------------------------------ EnrolmentStatus  update-----------------------------------
  submitNotes() {
    if (this.enrolementStatusId && this.activeTab === 'pending') {
      // this.isActivated=this.activatedData.is_activated =!this.activatedData.is_activated
      let body = {
        notes: this.enrolmentData.notes,
        status: 'cancel',
        // is_activated:this.isActivated
      }
      // console.log('=>>>>',body)
      this.apiService.updateEnrolment(this.enrolementStatusId, body).subscribe((res: any) => {
        if (res.isSuccess === true) {
          this.toastr.success('Update successfully')
          this.activeModal.close(res);
        }
      })
    }
    else if (this.enrolementStatusId && this.activeTab === 'enrolled' || this.activeTab === 'Classroom' || this.activeTab === 'Online' ) {
      // this.isActivated=this.activatedData.is_activated =!this.activatedData.is_activated
      let body = {
        notes: this.enrolmentData.notes,
        status: 'left',
        // is_activated:this.isActivated
      }
      // console.log('=>>>>',body)
      this.apiService.updateEnrolment(this.enrolementStatusId, body).subscribe((res: any) => {
        if (res.isSuccess === true) {
          // console.log('update res=>> ', res)
          this.toastr.success('Updated Successfully', res.message)
          this.activeModal.close(res);

          // this.apiService.getEnrollmentByStatus(this.activeTab, 1, 100).subscribe((res: any) => {
          //   // console.log('getEnrollmentByStatus', res)
          //   this.activeModal.close(res);
          // })
        }
      })
    }
    else if (this.enrolementStatus_Id) {
      let body = {
        notes: this.enrolmentData.notes,
        status: 'completed',
        // is_activated:this.isActivated

      }
      // this.isActivated=this.activated_Data.is_activated =!this.activated_Data.is_activated
      this.apiService.updateEnrolment(this.enrolementStatus_Id, body).subscribe((res: any) => {
        if (res.isSuccess === true) {
          // console.log('update res=>> ', res)
          this.toastr.success('Updated Seccessfully', res.message)
          this.apiService.getEnrollmentByStatus(this.activeTab, 1, 100).subscribe((res: any) => {
            // console.log('getEnrollmentByStatus', res)
            this.activeModal.close(res);
          })
        }
      })
    }

  }

}

