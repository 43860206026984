// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrls: {
  //   reports: 'http://192.168.0.122:1000/api',
  //   master: 'http://192.168.0.122:1000/api',
    reports: 'https://do.meander.software:1000/api',
    master: 'https://do.meander.software:1000/api',
    
    // reports: 'https://do.meander.software:5000/api',
    // master: 'https://do.meander.software:5000/api',
    rbUrl: 'https://cms.meander.software',
    rbGqlUrl: 'https://cms.meander.software/graphql',
    imageUrl:"https://do.meander.software:5000/assets/images/",
    course_imageUrl:'https://meandersoftware.s3.amazonaws.com/',
    pdfUrl:"https://do.meander.software:5000/assets/documents/",
    // new 
    s3MediaUrl:"https://meandersoftware.s3.eu-north-1.amazonaws.com/",
    baseUrl:'https://do.meander.software'
    // baseUrl: 'https://localhost:4200'
// old
    // s3MediaUrl:"https://meanderlive.s3.amazonaws.com/"

  },

};
