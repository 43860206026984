import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(wholeText: string, searchQuery: string): string {
    if (!searchQuery) {
      return wholeText;
    }
    const re = new RegExp(searchQuery, 'gi');
    return wholeText?.replace(re, '<mark>$&</mark>');
  }
}
