import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const ADMINROUTES: RouteInfo[] = [

  {
    path: '/dashboard', title: 'Dashboard',isShow: "admin ", icon: 'ft-home', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
    ]
  },
  {
    path: '', title: 'HRM',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/employees', title: 'Employees', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/my-attendence', title: 'My Attendance', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/leaves/list', title: 'Leave Request', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/openings/list', title: 'Openings', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/candidates-management/candidates-list', title: 'Candidates', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
              path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "admin ", class: 'has-sub', isExternalLink: false,
              submenu: [
                { path: '/leaves/reports', title: 'Leave Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
                { path: '/employees/team-reports', title: 'Team Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
                { path: '/employees-attendence/reports', title: 'Attendence Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
              ]
            },
    ]
  },
  {
    path: '', title: 'CRM',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/clients/lists', title: 'Clients', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },

      {
        path: '', title: 'Leads', icon: 'ft-arrow-right submenu-icon', isShow: "admin ", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: '/leads/outsource-leads', title: 'OutSourcing Lead', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/leads/training-leads', title: 'Training Lead', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        
        ]
      },
      { path: '/campaign/list', title: 'Campaigns', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
        path: '', title: 'Events', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false, submenu: [
          { path: '/events/current-list', title: 'Current', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/events/future-list', title: 'Future', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/events/past-list', title: 'Past', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      ] },
      { path: '/channel/list', title: 'Channel', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/business/list', title: 'Business', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/contact/list', title: 'Contacts', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '', title: 'OutSourcing Reports', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/leads/outsource-leads-reports', title: 'OutSource Leads', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/business/client-reports', title: 'Clients Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/business/company-reports', title: 'Company Report', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      ] },
      {
        path: '', title: 'Training Reports', icon: 'ft-arrow-right submenu-icon', isShow: "admin ", class: 'has-sub', isExternalLink: false,
        submenu: [
          
          { path: '/leads/training-leads-reports', title: 'Training Leads', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/business/college-reports', title: 'College Report', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/referral/reports', title: 'Referral Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          
        ]
      },  
      
    ]
  },
  {
    path: '', title: 'Work',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/jobs/contracts', title: 'Contract', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/jobs/projects', title: 'Projects', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/jobs/products', title: 'Products', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      // { path: '/jobs/list', title: 'Jobs', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '', title: 'Audit', isShow: "admin ",  icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false, submenu: [
        { path: '/project/project-list', title: 'Projects', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/project/product-list', title: 'Products', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/project/contract-list', title: 'Contracts', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      ]
     },

      { path: '/task/list', title: 'Task', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/tracker/list', title: 'Tracker', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      
      {
        path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "admin ", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: 'jobs/billing-reports', title: 'Billing', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        
        ]
      },
    ]
  },
  {
    path: '', title: 'Training',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/students-list', title: 'Students', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
        path: '', title: 'Enrollments', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false, submenu: [
          { path: '/enrollments/enrollment-pending-list', title: 'Pending', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [],},
          { path: '/enrollments/enrollment-classroom-list', title: 'Classroom', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [], },
          { path: '/enrollments/enrollment-online-list', title: 'Online', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [], },
          { path: '/enrollments/enrollment-completed-list', title: 'Completed', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [],},
      ] },
      { path: '/course/course-list', title: 'Courses', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '', title: 'Batches', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false,
       submenu: [
        { path: 'batches/batch-list', title: 'Batches', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
         { path: '/classes/list', title: 'Classes', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/classes/sessions', title: 'Sessions', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/batches/batch-audit', title: 'Batch-Audit', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },


       ]
      },
      { path: '', title: 'LMS', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'has-sub', isExternalLink: false,
       submenu: [
        { path: '/lms/list', title: 'V1', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/lmss/list', title: 'Content Apps', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/resources/list', title: 'Resources', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/discussions/list', title: 'Discussion', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },

       ] },
      {
        path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "admin", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: '/attendance/attendance-list', title: 'Students Attendace', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/dashboard/fees', title: 'Fees', isShow: "admin", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/placement-reports', title: 'Placements', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          
          { path: '/reports', title: ' Batch', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/student-onboarding/list', title: 'Onboarding', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/course/course-reports', title: 'Course Reports', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
         { path: '/lms/student-lms-report', title: 'Student LMS', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        ] 
      },     
    
    ]
  },
  {
    path: '', title: 'Utility',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/tickets/tickets-list', title: 'Ticket', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      // { path: '/research-base/tags', title: 'Research Base', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/credentials/list', title: 'Credentials', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: 'Financial Reports', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/', title: 'Settings', isShow: "admin ", icon: 'ft-arrow-right submenu-icon',  class: 'dropdown nav-item has-sub', isExternalLink: false, submenu: [
        { path: '/bulk-operations', title: 'Bulk Operations', isShow: "admin", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        { path: '/admin-settings', title: 'Admin Settings', isShow: "admin", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      ] },
      
      { path: '/assets/list', title: 'Digital Assets', isShow: "admin", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/guidelines', title: 'Guidelines', isShow: "admin", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Tools',isShow: "admin ", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/allUsers-list', title: 'Users', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/guest/contacts', title: 'Guest', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/categories/categories-list', title: 'Tag Categories', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/tags/tags-list', title: 'Tags', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      // { path: '/topic-content/list', title: 'Topic Contents', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/checklist/list', title: 'Checklist', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/discounts/discount-list', title: 'Discounts', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/notifications/notification-list', title: 'Notifications', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/control-management/list', title: 'Permissions', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },

    ]
  
    },

];
export const EXTRACTORROUTES: RouteInfo[] = [

  {
    path: '/dashboard', title: 'Dashboard',isShow: "extractor", icon: 'ft-home', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
    ]
  },
  {
    path: '', title: 'HRM',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/employee-crud', title: 'Employees', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/employees-attendence', title: 'My Attendance', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/leaves/list', title: 'Leave Request', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: 'Openings', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/candidates-management/candidates-list', title: 'Candidates', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
              path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "extractor", class: 'has-sub', isExternalLink: false,
              submenu: [
                { path: '/reports', title: 'Attendace Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
                { path: '/reports', title: 'Task Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
                { path: '/reports', title: 'Team Report', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
              
              ]
            },
    ]
  },
  {
    path: '', title: 'CRM',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      {
        path: '', title: 'Leads', icon: 'ft-arrow-right submenu-icon', isShow: "extractor ", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: '/leads/outsource-leads', title: 'OutSourcing Lead', isShow: "extractor ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/leads/training-leads', title: 'Training Lead', isShow: "extractor ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        
        ]
      },
      { path: '/dashboard1', title: 'Activities', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: 'Events', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/channel/list', title: 'Channel', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/business/list', title: 'Business', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/contact/list', title: 'Contacts', isShow: "admin ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
        path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "admin ", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: '/leads/outsource-leads-reports', title: 'OutSource Leads Reports', isShow: "extractor ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
          { path: '/leads/training-leads-reports', title: 'Training Leads Reports', isShow: "extractor ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        
        ]
      },        
    ]
  },
  {
    path: '', title: 'Contract',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/jobs/list', title: 'Jobs', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/project/project-list', title: 'Project Audit', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/task/list', title: 'Task', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      {
        path: '', title: 'Reports', icon: 'ft-arrow-right submenu-icon', isShow: "extractor", class: 'has-sub', isExternalLink: false,
        submenu: [
          { path: '/reports', title: 'Billing', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
        
        ]
      },
    ]
  },
  {
    path: '', title: 'Training',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/students-list', title: 'Students', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/enrollments/enrollment-list', title: 'Enrollments', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/course/course-list', title: 'Courses', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/batches/batch-list', title: 'Batches', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/lms/list', title: 'LMS', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/resources/list', title: 'Learning Base', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },   
    
    ]
  },
  {
    path: '', title: 'Utility',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/tickets/tickets-view', title: 'Ticket', isShow: "extractor ", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/research-base/tags', title: 'Research Base', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: 'Credentials', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: 'Financial Reports', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/reports', title: ' Admin Settings', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Tools',isShow: "extractor", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: [
      { path: '/allUsers-list', title: 'Users', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/categories/categories-list', title: 'Tag Categories', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/tags/tags-list', title: 'Tags', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/checklist/list', title: 'Checklist', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/discounts/discount-list', title: 'Discounts', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
      { path: '/notifications/notification-list', title: 'Notifications', isShow: "extractor", icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', isExternalLink: false, submenu: [] },
    ]
  
    }
];
export const STUDENTROUTES: RouteInfo[] = [

  {
    path: 'home/dashboard', title: 'Dashboard',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: 'learn', title: 'Learn',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: 'jobs/lists', title: 'Jobs',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '/resource', title: 'Resource',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '/events', title: 'Events',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },
  {
    path: '/discussions/my-discussions', title: 'Discussion',isShow: "student", icon: 'ft-box', class: 'dropdown nav-item has-sub', isExternalLink: false,
    submenu: []
  },

];
export var EMPLOYEEROUTES: RouteInfo[] = [
];
export const CLIENTROUTES: RouteInfo[] = [
];
