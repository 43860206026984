import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTo12Hour'
})
export class ConvertTo12HourPipe implements PipeTransform {
  transform(timeString: string): string {
    const [hours, minutes] = timeString.split(':').map(Number);

    const meridiem = hours >= 12 ? 'pm' : 'am';
    const twelveHour = hours % 12 || 12; // Convert 0 to 12

    return `${twelveHour}:${minutes.toString().padStart(2, '0')} ${meridiem}`;
  }
}