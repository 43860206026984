import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { ConvertTo12HourPipe } from './convertTo12Hour.pipe';
import { CustomDatePipe } from './customDate.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [FilterPipe, SearchPipe, ShortNamePipe, HighlightTextPipe,ConvertTo12HourPipe,CustomDatePipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [FilterPipe, SearchPipe, ShortNamePipe, HighlightTextPipe,ConvertTo12HourPipe,CustomDatePipe,SafeHtmlPipe]
})

export class PipeModule { }
