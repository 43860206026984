import { Component, ViewEncapsulation, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
    selector:'transaction-modal-content',
    templateUrl:'./transaction-modal.component.html',
    styleUrls: ['./transaction-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TransactionModalContent implements OnInit {
  transactionForm:FormGroup;

  @Input() public userId;  
  @Input() public userName;  
  @Input() public transactionData;    
  closeResult: string;
  @ViewChild('vform') validationForm: FormGroup;
  selectedValue:'';
  userData:any
  transactionFormSubmitted:boolean=false;
  transactionModel={
    paid_amount: '',
    student: '',
    entity_id: '',
    course_id:'',
    payment_status:'',
    next_payment_date:"",
    net_amount:'',
    pendingAmount:'',
    total_amount:''
  }
  min_next_payment_date: any
  paymentMode=[]
  selectedPaymentMode=null
  paymentModeId='631ada2ace5c666542591bf6'
  pamentStatusList = [
    // { name: 'pending' },
    { name: 'received',label:'Payment Received' },
    { name: 'due',label:'Payement Due' },
    // { name: 'completed',label:'Completed' },
    // { name: 'left',label:'Left' },
  ]
  selectedPaymentStatus='due'
  constructor(public activeModal: NgbActiveModal,private formBuilder: FormBuilder,
              public toastr: ToastrService,
              public apiService:ApiService,
              private route:Router,
              private modalService: NgbModal) { }
              
  ngOnInit() {
    console.log('===',this.userId, '',this.userName , '=>',this.transactionData)

    this.min_next_payment_date=new Date().toISOString().slice(0, 10);
   this.transactionForm=this.formBuilder.group({
    amount: ['', Validators.required],
    paymentMethod: ['', Validators.required],
    user: ['', Validators.required],
    notes: ['', Validators.required],
  })
  this.paymentModeList()

  }

 close(){
  this.modalService.dismissAll()
 }
  get rf() {
    return this.transactionForm.controls;
  }
//   submit(){
//     let body={
//       amount:this.transactionForm.value.amount,
//       paymentMethod:this.transactionForm.value.paymentMethod,
//       user:this.userId,
//       notes:this.transactionForm.value.notes
//     }
//     console.log('body val',body)
//    this.apiService.createTransaction(body).subscribe((res:any)=>{
//     if(res.statusCode === 200){
//       console.log('transaction create res',res)
//       this.toastr.success(res.message)
//       this.activeModal.close('success');
//     }
//     else{this.toastr.error(res.error)
//     }
//     this.transactionFormSubmitted = true;
//   if (this.transactionForm.invalid) {
//     return;
//   }
// })

//   }
paymentModeList(){
  this.apiService.getTagByTagCatgoryId(this.paymentModeId).subscribe((res: any) => {
    console.log('payment mode List', res)
    this.paymentMode = res.data
  })
}
getPaymentModeId(event:any){
  console.log('selectedPaymentMode',this.selectedPaymentMode)
}
  paymentSubmit(){
    let pendingFees=this.transactionData.pendingAmount - Number(this.transactionModel.paid_amount)
    let body = {
      paid_amount: this.transactionModel.paid_amount,
      student: this.transactionData[0].student,
      entity_id: this.transactionData[0].entity_id,
      course_id:this.transactionData[0].course_id._id,
      net_amount:this.transactionData[0].net_amount,
      pendingAmount:pendingFees,
      total_amount:this.transactionData[0].course_id.total_fees,
      payment_status:this.selectedPaymentStatus,
      mode:this.selectedPaymentMode
    }
    console.log("body",body)
    this.apiService.createTransaction(body).subscribe((res: any) => {
      if (res.statusCode === 200) {
        console.log('transaction create res', res)
        this.toastr.success(res.message)
        this.activeModal.close( "success" );
      }
      else {
        // this.toastr.error(res.error)
      }
    })
  }

//  back(){
//   this.route.navigate(['/transaction/transaction-list'])    
//  } 
}
@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TransactionModalComponent implements OnInit {
    closeResult: string;
  @Input() public userName; 
  @Input() public userId;  
  @Input() public transactionData;    


    @ViewChild('vform') validationForm: FormGroup;
  
    transactionForm:FormGroup;
    selectedValue:'';
    users:any;
    transactionFormSubmitted:boolean=false;
    transactionModel={
      paid_amount: '',
      student: '',
      entity_id: '',
      course_id:'',
      payment_status:'',
      next_payment_date:"",
      net_amount:'',
      pendingAmount:'',
      total_amount:''
    }
    min_next_payment_date: any
    paymentMode=[]
    selectedPaymentMode=null
    paymentModeId='631ada2ace5c666542591bf6'
    pamentStatusList = [
      // { name: 'pending' },
      { name: 'received',label:'Payment Received' },
      { name: 'due',label:'Payement Due' },
      // { name: 'completed',label:'Completed' },
      // { name: 'left',label:'Left' },
    ]
    selectedPaymentStatus='due'
    constructor(private formBuilder: FormBuilder,
                public toastr: ToastrService,
                public apiService:ApiService,
                private route:Router,
                private modalService: NgbModal,
                public activeModal: NgbActiveModal) { }
                
    ngOnInit() {
      this.paymentModeList()
      // this.getAllUsers()
      this.min_next_payment_date=new Date().toISOString().slice(0, 10);
      this.transactionForm=this.formBuilder.group({
        amount: ['', Validators.required],
        paymentMethod: ['', Validators.required],
        user: ['', Validators.required],
        notes: ['', Validators.required],
      })
    }
    // Open default modal
    open(content) {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    // This function is used in open
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    // Open modal with dark section
    openModal(customContent) {
        this.modalService.open(customContent, { windowClass: 'dark-modal' });
    }

    // Open content with dark section
    openContent() {
        const modalRef = this.modalService.open(TransactionModalContent);
        modalRef.componentInstance.name = 'World';
    }
    getAllUsers(){
      this.apiService.getAllUsers(1,100).subscribe((res:any)=>{
        this.users =res.data.users  
        console.log('users list ==',this.users)
      })
    }
    getUserId(event:any){
      this.selectedValue=event.target.value
      console.log('select user id',this.selectedValue)
    }
   
    get rf() {
      return this.transactionForm.controls;
    }
  //   submit(){
  //     let body={
  //       amount:this.transactionForm.value.amount,
  //       paymentMethod:this.transactionForm.value.paymentMethod,
  //       user:this.userId,
  //       notes:this.transactionForm.value.notes
  //     }
  //     console.log('body val',body)
  //    this.apiService.createTransaction(body).subscribe((res:any)=>{
  //     if(res.statusCode === 200){
  //       console.log('transaction create res',res)
  //       this.toastr.success(res.message)
  //     }
  //     else{this.toastr.error(res.error)
  //     }
  //     this.transactionFormSubmitted = true;
  //   if (this.transactionForm.invalid) {
  //     return;
  //   }
  // })

  //   }
    
 close(){
  this.modalService.dismissAll()
 }
 paymentModeList(){
  this.apiService.getTagByTagCatgoryId(this.paymentModeId).subscribe((res: any) => {
    console.log('payment mode List', res)
    this.paymentMode = res.data
  })
}
getPaymentModeId(event:any){
  console.log('selectedPaymentMode',this.selectedPaymentMode)
}
 paymentSubmit(){
  let pendingFees=this.transactionData.pendingAmount - Number(this.transactionModel.paid_amount)
  let body = {
    paid_amount: this.transactionModel.paid_amount,
    student: this.transactionData[0].student,
    entity_id: this.transactionData[0].entity_id,
    course_id:this.transactionData[0].course_id._id,
    net_amount:this.transactionData[0].net_amount,
    pendingAmount:pendingFees,
    total_amount:this.transactionData[0].course_id.total_fees,
    payment_status:'received',
    mode:this.selectedPaymentMode
  }
  console.log("body",body)
  this.apiService.createTransaction(body).subscribe((res: any) => {
    if (res.statusCode === 200) {
      console.log('transaction create res', res)
      this.toastr.success(res.message)
      this.activeModal.close( "success" );
    }
    else {
      // this.toastr.error(res.error)
    }
  })
}


}
