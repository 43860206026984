import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({ name: 'safeHtml' })

export class SafeHtmlPipe implements PipeTransform {

constructor(private sanitizer: DomSanitizer) { }
transform(value: string): SafeHtml {
  const sanitizedValue = value.replace(/width="[^"]+"/, 'width="100%"');
  return this.sanitizer.bypassSecurityTrustHtml(sanitizedValue);
}
}