import { Component,  OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from './shared/services/local-storage.service';


@Component({
    selector: 'app-root',
//     template: `
//     <div>
//       <app-counter></app-counter>
//     </div>
//   `,
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,    private deviceService:DeviceDetectorService,
        private store: LocalStorageService) {
    }

    ngOnInit() {
        this.store.setItem('os',this.deviceService.getDeviceInfo().os);
        this.store.setItem('browser',this.deviceService.getDeviceInfo().browser)
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}