import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Tag } from '../models/tag.model';
import { Tag_Category } from '../models/tag_category.model';
// import { LocalStorageService } from './local-storage.service';
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    root = environment.apiUrls.reports;
    userResponse: any;
    usersData: any;
    categoryResponse: any;
    tagResponse: any;
    ForumResponse: any;
    token: any = ''

    constructor(private http: HttpClient) { }
    getPicUrl(pic: any) {
        const subject = new Subject<any>();
        this.http.post(`${this.root}/uploads/getPicUrl`, pic).subscribe((response) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        }
        );
        return subject.asObservable();

    }

    addCategory(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/categories/add`, model).subscribe((responseData) => {
            this.categoryResponse = responseData;
            subject.next(this.categoryResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }

    submitExpense(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/expenses/create`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    checkIfAlreadyExist(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/users/checkuserexist`, model).subscribe((responseData: any) => {
            subject.next(responseData);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    updateExpense(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/expenses/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }




    submitTimesheet(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/timeSheets/create`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }

    updateTimesheet(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/timeSheets/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------get getTimesheet-------------------
    getTimesheet(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/getWeekly/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // ------------------- getTimesheet by timesheet id-------------------
    getTimesheetbyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/getById/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get all getTimesheetWeekly by Agency-------------------
    getAllTimesheetWeekly(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/getLastUpdated/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get timesheet by userid-------------------
    getAllTimesheetbyUser(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/getByUser/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get timesheet by agencyId-------------------
    getAllTimesheetbyAgencyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/thisWeekTimeSheetsByagency/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    getAllTimesheet(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/getAll`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------get getExpensebyUser last added-------------------
    getExpensebyUser(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/getWeeklyData/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get getExpense All-------------------
    getExpenseAll(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/getAll`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ------------------- getExpense by Expense id-------------------
    getExpensebyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/getById/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    // ------------------- getExpenseByAgencyId by agency id-------------------
    getExpenseByAgencyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/thisWeekExpensesByagency/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    // -------------------get getExpensebyUser-------------------
    getAllExpensebyUser(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/getByUser/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get all getLastExpense by Agency-------------------
    getLastExpense(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/getLastUpdated/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    userActiveInActive(id: any, isActivated: any) {
        const subject = new Subject<any[]>();
        let m;
        this.http.put(`${this.root}/users/activeOrDeactive?id=${id}&isActivated=${isActivated}`, m).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    getAllAgencies(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/agencies/getAll`,).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse.data);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    getLogoByAgencyName(name: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/agencies/getLogoByName/${name}`,).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse.data);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    uploadUserImage(id: any, model: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/users/uploadProfile/${id}`, model).subscribe((response) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        }
        );
        return subject.asObservable();
    }

    addUser(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/users/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    addStudent(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/student/addStudent`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    updateUser(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/users/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    updateSignature(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/users/signatureUpdate/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    editUser(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/users/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    addAgency(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/agencies/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    addManager(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/managers/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    updateAgency(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/agencies/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    getUserbyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/getById/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject?.next(this.userResponse);
        }, (error) => {
            subject?.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------- Get Enrollment By User Id --------------------------------------------

    getEnrollByUserId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/enrolmentGetByUser/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -----------------------------------------------------------------------------------------

    getUserbyAgencyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/getAgencyUsers/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    getStudentbyAgencyId(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/byAgencyId/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    deleteUser(id: any, password): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/users/deleteUserbyId/${id}?password=${password}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    deleteAgency(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        let a;
        this.http.delete(`${this.root}/agencies/delete/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // ----------------------------request OTP --------------------------------------
    requestOtp(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/sendOtp/${id}`).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // ----------------------------verify OTP --------------------------------------
    verifyOtp(modle: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/users/verifyOtp`, modle).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    searchProviderByEmail(key: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/providers/byEmialId?email=${key}`,).subscribe((responseData: any) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    providerListByFilter(city: any, state: any, country: any, source: any, type: any, sex: any, pageSize: any, pageNo: any): Observable<any[]> {
        let querry: any;
        let value: any;
        if (city) {
            querry = 'city';
            value = city;
        }
        if (state) {
            querry = 'state';
            value = state;
        }
        if (country) {
            querry = 'country';
            value = country;
        }
        if (type) {
            querry = 'type';
            value = type;
        }
        if (sex) {
            querry = 'sex';
            value = sex;
        }
        if (source) {
            querry = 'source';
            value = source;
        }
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/providers/listByFilter?${querry}=${value}&pageSize=${pageSize}&pageNo=${pageNo}`).subscribe((responseData: any) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });

        return subject.asObservable();
    }

    // -------------------get sendExpenseReminder by id-------------------
    sendExpenseReminder(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/expensReminder/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get sendTimesheetReminder by id-------------------
    sendTimesheetReminder(id: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/timeSheetReminder/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    // -------------------get timeSheetfilter-------------------
    timeSheetfilter(filter: any, value: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/timeSheets/filterTimeSheet?filter=${filter}&value=${value}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------get expenseFilter-------------------
    expenseFilter(filter: any, value: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/expenses/filterExpense?filter=${filter}&value=${value}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    emplyeeFilter(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/searchEmployees?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    studentFilter(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/searchStudent?name=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }



    clientFilter(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/client/searchClient?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------------------change password user----------------------------

    changePassword(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/users/changePassword/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Users List -----------------------------------------------------

    getAllUsers(pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    
    // -------------------------- Get Users By Status -----------------------------------------------------

    getAllUsersByStatus(pageNo, pageSize, role): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/userGetByRole?pageNo=${pageNo}&pageSize=${pageSize}&role=${role}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Users Filter -----------------------------------------------------
    getUsersSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Get Users By Reference Id -----------------------------------------------------

    getUsersByReferenceId(id): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/getByRefrence/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- User Disable User By Id ---------------------------------------------------------
    userDisableById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/users/disableuserbyId/${id}`, '').subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Students List -----------------------------------------------------

    getAllStudents(pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // --------------------------Enrolled Students List -----------------------------------------------------

    getAllEnrollStudents(pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/getAllEnrolledStudents?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Students Performer Reports -------------------------------------------------

    getStudentsReports(pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/studentReport?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Students Placement Reports -----------------------------------------------------

    getStudentsPlacementReports(pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/Report?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Students Search -----------------------------------------------------

    getStudentBySearch(pageNo, pageSize, search): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/search?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------- Get Students By Id --------------------------------------------------------
    getStudentById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/student/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Student ---------------------------------------------------------
    deleteStudent(id: any, password: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/student/delete/${id}?password=${password}`,).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Student Update ---------------------------------------------------------
    updateStudent(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/student/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Student Change Role ---------------------------------------------------------
    changeRole(id: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/users/changeRole/${id}`,'').subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Roles ---------------------------------------------------------

    getRoles(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/roles/getRoles`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Enrolment Create ---------------------------------------------------------

    createEnrolment(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/enrolments/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Enrolment ---------------------------------------------------------
    getAllEnrolments(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Enrolment ---------------------------------------------------------
    getFilterEnrolments(search: any, status: any): Observable<any[]> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/enrolments/search?search=${search}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Enrolment Hunting By ---------------------------------------------------------
    getFilterHuntingByEnrolments(id: any, pageNo: any, pageSize: any, status: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/enrolmentGetByHunting/${id}?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Enrolment Get By Batch Id ---------------------------------------------------------
    getEnrolmentsByBatchId(id: any,instructor:any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/enrolments/enrolmentgetByBatchId/${id}?instructor=${instructor}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get  Enrolment by status ---------------------------------------------------------
    getEnrollmentByStatus(status: any, pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/enrolmentsgetbystaus?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Check Phone No. Exists  Enrolment ---------------------------------------------------------
    checkPhoneExists(Phone): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/getByPhone/${Phone}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Check User Enrollment ---------------------------------------------------------
    checkUserEnrollment(data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/users/checkUser`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get  Payment by status Due ---------------------------------------------------------
    getPaymentDue(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/calculate?status=enrolled&payment_status=due&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------------------------  getTrainingTransactionReports ---------------------------------------------------------
    getTrainingTransactionReports(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/getTrainingTransactionReports`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------------------------  listPendingAndDueStudentList ---------------------------------------------------------

    listPendingAndDueStudentList(query): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/listPendingAndDueStudentList?${query}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get  Payment Pending ---------------------------------------------------------
    getPaymentPending(status, pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/calculatePending?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------- Get Enrolment By Id --------------------------------------------------------
    getEnrolmentById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------- Get Enrolment By User Id --------------------------------------------------------
    getEnrolmentByUserId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/enrolmentgetByUserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Enrolment Update ---------------------------------------------------------
    updateEnrolment(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/enrolments/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Enrolment Delete ---------------------------------------------------------
    deleteEnrolment(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/enrolments/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------------- Enrolment  Month visit------------------------------
    monthlyVisitEnrolment(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/MonthlyVisit?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------------- Enrollment Get By Learning Mode ---------------------------------------
    getEnrollmentByMode(id:any,pageNo: any, pageSize: any,status:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/enrolmentsgetByMode/${id}?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- changePasswordEnrolment ---------------------------------------------------------
    changePasswordEnrolment(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/enrolments/resetPassword/${id}`, '').subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Tag Create ---------------------------------------------------------
    tagCreate(data: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.post(`${this.root}/tags/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Tag Filter -----------------------------------------------------
    getTagSearch(search: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/tags/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags List ---------------------------------------------------------
    getTagsList(pageNo: any, pageSize: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/tags/getTags?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags Id ---------------------------------------------------------

    getTagById(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/tags/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags By Tag Category Id ---------------------------------------------------------

    getTagByTagCatgoryId(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/tags/getTagByTag_categoriesId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Tag Update ---------------------------------------------------------
    tagUpdate(id: any, data: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.put(`${this.root}/tags/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Tag Delete ---------------------------------------------------------
    tagDelete(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.delete(`${this.root}/tags/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- create course ---------------------------------------------------------
    createCourse(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/course/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Course Filter -----------------------------------------------------
    getCourseSearch(name: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/search?name=${name}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Course Grouping Filter -----------------------------------------------------
     getCourseGroupingSearch(name: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/searchCoursesGrouping?name=${name}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Course list ---------------------------------------------------------

    getCourseList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/getcourses?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Course Grouping list ---------------------------------------------------------

    getCourseGroupingList(status:any,pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/courseGrouping?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // ----------Course Grouping list By Category ------------------------------

    getCourseGroupingListByCategory(status:any,publish_category:any,publish_tag:any,pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/courseGroupingByCategory?status=${status}&publish_category=${publish_category}&publish_tag=${publish_tag}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get course Id ---------------------------------------------------------

    getCourseById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
       // -------------------------- Get Course By Course tag Id ---------------------------------------------------------

       getCourseByCourseTagId(id: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/course/getCoursesByCourseTag/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------- Course Password -------------------------
    coursePassword(id: any,password:any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/course/getByPassword/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------- Add Course Meta Information -------------------------
    addcourseMeta(id: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/course/getMetaDetail/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- update course ---------------------------------------------------------

    updateCourse(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/course/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- update course image ---------------------------------------------------------

     updateCourseImg(id: any,formdata): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/course/imageUpdateById/${id}`,formdata).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Course Delete ---------------------------------------------------------
    courseDelete(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/course/delete/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ------------------- Password For Update Course ---------------------------------
    passwordToUpdateCourse(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/course/updateCourseCredential/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Check Course Have Password -----------------------------------------------------
    getCourseIsPassword(id: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/course/checkPasswordById/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Project Filter -----------------------------------------------------
    getProjectSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/project/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Project List ---------------------------------------------------------
    getProjectList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/project/getProject?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- create project ---------------------------------------------------------

    createProjectList(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/project/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get project by Id ---------------------------------------------------------

    getProjectById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/project/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- update project ---------------------------------------------------------

    updateProject(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/project/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Project Delete ---------------------------------------------------------
    projectDelete(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/project/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create Category ---------------------------------------------------------

    createCategory(data: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.post(`${this.root}/tagcategories/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Category Filter -----------------------------------------------------
    getCategorySearch(search: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/tagcategories/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Category ---------------------------------------------------------

    getAllCategory(pageNo: any, pageSize: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/tagcategories/gettag_categories?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Category By Id ---------------------------------------------------------

    getCategoryById(id: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/tagcategories/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Category ---------------------------------------------------------

    updateCategory(id: any, model: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.put(`${this.root}/tagcategories/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Category ---------------------------------------------------------
    jobsDelete(id: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.delete(`${this.root}//jobs/deleteJobs/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Business Create --------------------------------------------------------------

    createBusiness(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/business/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- contact Create --------------------------------------------------------------

    createContact(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/contact/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- contact update --------------------------------------------------------------

    updateContact(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/contact/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get contact By Id ---------------------------------------------------------

    getContactById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/contact/contactgetbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Task Create --------------------------------------------------------------

    createTask(data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/task/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Task update --------------------------------------------------------------

    updateTask(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/task/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Task Order Change --------------------------------------------------------------

    taskOrderChange(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/task/changeOrder`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Task ---------------------------------------------------------
    getAllTask(pageNo, pageSize): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/task/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get By Priority Task ---------------------------------------------------------
    getTaskByPriority(id: any, pageNo, pageSize): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/task/GetByPriority/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
       // -------------------------- Search Task ---------------------------------------------------------
       getTaskSearch(status,stage, to, priority,is_schedule,type_checklist,pageNo,pageSize): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/task/taskSearch?Status=${status}&Stage=${stage}&To=${to}&Priority=${priority}&Isschedule=${is_schedule}&TypeChecklist=${type_checklist}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Task By Id ---------------------------------------------------------

    getTaskById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Task By User Id ---------------------------------------------------------

    getTaskByUserId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/getUserTask/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Task By User Id & Date---------------------------------------------------------

    getTaskByUserIdWithDate(id: any,FromDate:any,ToDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/getTaskByUserAndDate/${id}?FromDate=${FromDate}-10&ToDate=${ToDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Task By Label Id ---------------------------------------------------------

     getTaskByLabelId(id: any,pageNo:any,pageSize:any,FromDate:any,ToDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/getByLabel/${id}?pageNo=${pageNo}&pageSize=${pageSize}&FromDate=${FromDate}&ToDate=${ToDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Task ---------------------------------------------------------
    taskDelete(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/task/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Task By Status ---------------------------------------------------------

    getTaskByStatus(id: any,pageNo:any, pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/taskGetByStatus/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Get Task By Stage ---------------------------------------------------------

    getTaskByStage(id: any,pageNo:any, pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/taskGetByStage/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
      // -------------------------- Get Task By Status id And checklist id ---------------------------------------------------------

      getTaskByStatusChecklistId(id: any,status:any,pageNo:any, pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/getByCheckList/${id}?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }  
    // --------------------------Task Filter without Grouping -----------------------------------------------------
    taskSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/searchByName?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // --------------------------Task Filter By Body & Admin Notes -----------------------------------------------------
     taskSearchBy(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/task/Search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Student By Leads Id ---------------------------------------------------------

    getStudentByleadsId(typeId: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leads/getStudentbyleadsId?typeId=${typeId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Business Filter -----------------------------------------------------
    getBusinessSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Business Filter -----------------------------------------------------
    contactSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/contact/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Business List ---------------------------------------------------------

    getBusinessList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Business List By Type ---------------------------------------------------------

    getBusinessListBytype(college: any, comapny: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/getByTypes?college=${college}&comapny=${comapny}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    getBusinessListByTypeComp(comapny: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/getByTypes?comapny=${comapny}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Contact List ---------------------------------------------------------

    getContactList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/Contact/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Get Guest Contact List ---------------------------------------------------------

     getGuestContactList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/contact/getAllGuestUser?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Get Contact By Business Id ---------------------------------------------------------

    getContactByBusinessId(id): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/contact/contactGetByBusinessId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Leads  ---------------------------------------------------------

    createLeads(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.post(`${this.root}/leads/add`, data).subscribe(res => {
        this.http.post(`${this.root}/trainingLeads/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Outsource Leads Reports ---------------------------------------------------------

    getOutSourceLeadsReports(query): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.get(`${this.root}/leads/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
        this.http.get(`${this.root}/outsourcingLeads/grouping?${query}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Outsource Leads Reports ---------------------------------------------------------

    getleadStageReports(id,fromDate?,toDate?): Observable<any> {
        const subject = new Subject<any>();
        let url = `${this.root}/leads/leadsReports`;
        let leadId = id
        // Append the provider ID to the URL if it exists.
        if (leadId) {
        url += `/${leadId}`;
        }

        // Append the city ID, change detection, page size, page number, isArchived, and verified status to the URL if they exist.
        if (fromDate &&toDate) {
        url += `${leadId ? '?' : ''}fromDate=${fromDate}&toDate=${toDate}`;
        }
        this.http.get(url).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Training Leads Reports ---------------------------------------------------------

    getTrainingLeadsReports(query): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/trainingLeads/grouping?${query}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Leads List ---------------------------------------------------------
    getLeadsList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.get(`${this.root}/leads/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
        this.http.get(`${this.root}/leads/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Leads ---------------------------------------------------------

    getLeadsStatusList(category): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leads/getleadsbyQuery?category=${category}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- OutSource Leads Delete Close Status ---------------------------------------------------------

     getOutSourceLeadsDeleteCloseStatus(password): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingLeads/deletecloseAlloutsourcingLeads?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Leads Move to Close Status ---------------------------------------------------------

    getLeadsMoveToCloseStatus(typeId: any, leadStatus: any, toUpdateLeadStatus: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/leads/ActiveToClose?typeId=${typeId}&leadStatus=${leadStatus}&toUpdateLeadStatus=${toUpdateLeadStatus}`, '').subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update leads ---------------------------------------------------------

    updateLeads(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/leads/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete leads ---------------------------------------------------------
    deleteleads(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/leads/remove/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete leads ---------------------------------------------------------
    deleteOutsourceLeads(id: any, password: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/outsourcingLeads/remove/${id}`, password).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------------------------- Leads Filter ------------------------------------------------------------
    getLeadsSearch(id, search: any, status: any, statusName: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leads/search/${id}?search=${search}&lead_status=${status}&statusName=${statusName}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Leads By Id ---------------------------------------------------------

    getLeadsById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leads/getleadsbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Leads By TagId ---------------------------------------------------------
    getLeadsByTagId(pageNo, pageSize, id: any, status, statusName: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leads/getleadsbytagId/${id}?pageNo=${pageNo}&pageSize=${pageSize}&lead_status=${status}&statusName=${statusName}`).subscribe(res => {
            // this.http.get(`${this.root}/trainingLeads/gettrainingleadsbytagId/${id}?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Check Training Leads By Phone Exists/Not ---------
    isTrainingLeadsByPhoneExists(phone: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/trainingLeads/getPhoneByTrainingLead/${phone}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Outsource Leads By TagId ---------------------------------------------------------
    getOutsourceLeadsByTagId(pageNo, pageSize, status, typeId,statusName): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingLeads/getoutsourcingleadsbytagId?pageNo=${pageNo}&pageSize=${pageSize}&leadStatus=${status}&typeId=${typeId}&statusName=${statusName}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Outsource Leads By TagId ---------------------------------------------------------
    getOutsourceLeadsById(id): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingLeads/getoutsourcingLeadsbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------------------------- Leads Filter ------------------------------------------------------------
    getOutSourceLeadsSearch(search: any, status: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingLeads/search?search=${search}&status=${status}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Leads By Leads Type ---------------------------------------------------------

    getLeadsByTypes(pageNo, pageSize, leadtype, leadid): Observable<any[]> {
        const subject = new Subject<any[]>();
        let query = `&${leadtype}=${leadid}`
        this.http.get(`${this.root}/leads/getByTypes?pageNo=${pageNo}&pageSize=${pageSize}${query}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Training Leads  ---------------------------------------------------------

    createTrainingLeads(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.post(`${this.root}/leads/add`, data).subscribe(res => {
        this.http.post(`${this.root}/trainingLeads/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Training leads ---------------------------------------------------------

    updateTrainingLeads(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.put(`${this.root}/leads/update/${id}`, model).subscribe((responseData) => {
        this.http.put(`${this.root}/trainingLeads/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Add OutSource Leads  ---------------------------------------------------------

    createOutSourceLeads(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.post(`${this.root}/leads/add`, data).subscribe(res => {
        this.http.post(`${this.root}/outsourcingLeads/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update OutSource leads ---------------------------------------------------------

    updateOutSourceLeads(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        // this.http.put(`${this.root}/leads/update/${id}`, model).subscribe((responseData) => {
        this.http.put(`${this.root}/outsourcingLeads/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Create Jobs ---------------------------------------------------------

    createJob(data: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.post(`${this.root}/jobs/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Jobs Filter -----------------------------------------------------
    getJobsSearch(search: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/tagcategories/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Jobs ---------------------------------------------------------

    getAllJobs(pageNo: any, pageSize: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/jobs/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Jobs By Id ---------------------------------------------------------

    getJobsById(id: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/jobs/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Jobs By Status ---------------------------------------------------------

    getJobsByStatus(Status: any, pageNo: any, pageSize: any, WorkOn: any, statusName: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.get(`${this.root}/jobs/getByStatus?pageNo=${pageNo}&pageSize=${pageSize}&Status=${Status}&WorkOn=${WorkOn}&statusName=${statusName}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Job ---------------------------------------------------------

    updateJob(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/jobs/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // --------------------------Upload Job Image ---------------------------------------------------------

    uploadJobImage(id: any, model: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/jobs/jobProfilePicture/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Jobs ---------------------------------------------------------
    jobDelete(id: any, password): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.delete(`${this.root}/jobs/deleteJobs/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Refrences ---------------------------------------------------------

    getJobByWorkOn(id: any, pageNo: any, pageSize: any): Observable<[]> {
        const subject = new Subject<[]>();
        this.http.get(`${this.root}/jobs/getByWorkon/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Jobs ---------------------------------------------------------
    getFilterJobs(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobs/search?search=${search}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Reference ---------------------------------------------------------
    refDelete(id: any, password): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.delete(`${this.root}/refrences/remove/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create Reference ---------------------------------------------------------

    createReference(data: any): Observable<[]> {
        const subject = new Subject<[]>();
        this.http.post(`${this.root}/refrences/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Refrences ---------------------------------------------------------

    getAllReference(pageNo: any, pageSize: any): Observable<[]> {
        const subject = new Subject<[]>();
        this.http.get(`${this.root}/refrences/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Category ---------------------------------------------------------
    categoryDelete(id: any): Observable<Tag_Category[]> {
        const subject = new Subject<Tag_Category[]>();
        this.http.delete(`${this.root}/tagcategories/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Channel Create ---------------------------------------------------------

    createChannel(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/channels/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Channel ---------------------------------------------------------
    getAllChannels(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/channels/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Channels For All Technology------------------------------------------
    getAllChannelsAllTechnologies(): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/channels/getChannelCategoryAndTechnologyWise`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Channel ---------------------------------------------------------
    getFilterChannels(search: any, status: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/channels/search?search=${search}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Channel By Category ---------------------------------------------------------
    getChannelsByCategory(id, technologyId): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/channels/getchannelsbyCategory/${id}/${technologyId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Channel ---------------------------------------------------------

    updateChannel(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/channels/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Channel ---------------------------------------------------------
    deleteChannel(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/channels/remove/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Business By Id ---------------------------------------------------------

    getChannelById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/channels/getchannelsbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Candidates Management Create ---------------------------------------------------------

    createCandidates(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/candidatesmanagement/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Candidates Management ---------------------------------------------------------
    getAllCandidates(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/candidatesmanagement/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    
    // -------------------------- Get All Candidates Management ---------------------------------------------------------
    getCandidatesByQuery(status: any, id: any,applied_for:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobopening/getCandidateByQuery?status=${status}&id=${id}&applied_for=${applied_for}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Update Candidates Management ---------------------------------------------------------

    updateCandidates(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/candidatesmanagement/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Candidates Management ---------------------------------------------------------
    deleteCandidates(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/candidatesmanagement/remove/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Candidates Management By Id ---------------------------------------------------------

    getCandidatesById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/candidatesmanagement/candidatesmanagementgetbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Candidates Management ---------------------------------------------------------
    getFilterCandidates(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/candidatesmanagement/search?search=${search}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Business By Id ---------------------------------------------------------

    getBusinessById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Business By Id ---------------------------------------------------------

    getBusinessByTypeId(type_id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/business/getByTypeId?type=${type_id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Business ---------------------------------------------------------

    updateBusiness(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/business/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Business ---------------------------------------------------------
    deleteBusiness(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/business/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Contact ---------------------------------------------------------
    deleteContact(id: any,password:any): Observable<any> {
        const subject = new Subject<any>();
        this.http.delete(`${this.root}/contact/remove/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Job Transaction --------------------------------------------------------------

    createJobTransaction(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/outsourcingtransaction/createTransaction`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Job Transaction List ---------------------------------------------------------

    getJobTransactionList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/jobgetAlltransactions?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Transaction By Job Id ---------------------------------------------------------

    getTransactionByJobId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/getTransactionByJobId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Job Transaction ---------------------------------------------------------

    updateJobTransaction(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/outsourcingtransaction/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Job Transaction ---------------------------------------------------------
    deleteJobTransaction(id: any, password): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/outsourcingtransaction/delete/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // ---------------- Transaction Create --------------------------------------------------------------

    createTransaction(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/transaction/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Transaction List ---------------------------------------------------------

    getTransactionList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/getTransactions?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Transaction By Transaction Id ---------------------------------------------------------

    getTransactionById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Transaction By User Id ---------------------------------------------------------

    getTransactionUserById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/getTransactionByUserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Transaction By Enrollment Id ---------------------------------------------------------

    getTransactionByEnrollId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/GetTransactionByEnrolmentId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Transaction ---------------------------------------------------------

    updateTransaction(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/transaction/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Transaction ---------------------------------------------------------
    deleteTransaction(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/transaction/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Calculate Transaction ---------------------------------------------------------
    getTransactionByMonth(month, year): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/transaction/GetTransactionsbyMonth?year=${year}&month=${month}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Batch Create --------------------------------------------------------------

    createBatch(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/Batch/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Batch List ---------------------------------------------------------

    getBatchList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/batch/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Batch List By Status ---------------------------------------------------------

    getBatchListByStatus(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/batch/BatchgetByStatus/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
      // -------------------------- Get Batch List By Instructor ---------------------------------------------------------

      getBatchListByInstructor(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/batch/batchGetByInstuctor/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Get Batch By Id ---------------------------------------------------------

    getBatchById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/batch/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Batch ---------------------------------------------------------

    updateBatch(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/batch/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Batch ---------------------------------------------------------
    deleteBatch(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/batch/delete/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Attendance Create --------------------------------------------------------------

    createAttendance(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/attendance/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Attendance List ---------------------------------------------------------

    getAttendanceList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Attendance By Id ---------------------------------------------------------

    getAttendanceById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    // -------------------------- Get Attendance By User Id ---------------------------------------------------------

    getAttendanceByUserId(id: any,pageNo: any, pageSize: any,startDate:any,endDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getAttendanceByUserId/${id}?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Attendance ---------------------------------------------------------

    updateAttendance(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/attendance/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Attendance ---------------------------------------------------------
    deleteAttendance(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/attendance/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- filter Attendance ---------------------------------------------------------

    getAttendenceSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // ---------------- Create Notification --------------------------------------------------------------

    createNotification(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/notification/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Notification List ---------------------------------------------------------

    getNotificationList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/notification/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Notification By User Id ---------------------------------------------------------

    getNotificationByUserId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/Notification/getNotificationByUserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Attendance ---------------------------------------------------------

    updateNotification(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/Notification/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Notification ---------------------------------------------------------
    deleteNotification(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/Notification/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Discounts Create ---------------------------------------------------------

    createDiscounts(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/discounts/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Discount ---------------------------------------------------------
    getAllDiscounts(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/discounts/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------- Get Discount By Id --------------------------------------------------------
    getDiscountById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/discounts/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Discount Update ---------------------------------------------------------
    updateDiscount(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/discounts/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Discount Delete ---------------------------------------------------------
    deleteDiscount(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/discounts/permanentdelete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------------------- Get Discount By Course Id --------------------------------------------------------
    getDiscountByCourseId(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/discounts/calculatediscounts`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // discounts/calculatediscounts/{courseId}/{discountId}

    // -------------------------- Add Employee ---------------------------------------------------------

    addEmployee(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/employee/add`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Upload Csv file to Add Employee ---------------------------------------------------------

    addEmployeeFromCSV(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/candidatesmanagement/excelToDb`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Get Employee List ---------------------------------------------------------

    getEmployeeList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Full Time & Part Time Employee List ---------------------------------------------------------

    getFullPartEmployeeList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/employeeAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee By Team Id ---------------------------------------------------------

    getEmployeeByTeamId(id: any, pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/getEmployeeByTeam/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee By Type Id ---------------------------------------------------------

    getEmployeeByTypeId(id: any, pageNo: any, pageSize: any,status:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/EmployeegetByType/${id}?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Employee Grouping By Type Id ---------------------------------------------------------

    getEmployeeGroupingByTypeId(id: any, status:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/EmployeeGetByTypeGrouping/${id}?status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee List By Status ---------------------------------------------------------

    getEmployeeListByStatus(id: any, pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/EmployeegetByStatus/${id}?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee List  Grouping By Status ---------------------------------------------------------

    getEmployeeListGroupingByStatus(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/EmployeegetByStatusGrouping/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Employee---------------------------------------------------------

    updateEmployee(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/employee/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete Employee ---------------------------------------------------------
    deleteEmployee(id: any, password: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/employee/remove/${id}?password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee By Id ---------------------------------------------------------

    getEmployeeById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- filter Employee ---------------------------------------------------------

    getEmployeeSearch(search: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- filter Employee Grouping ---------------------------------------------------------

    getEmployeeSearchGrouping(search: any,employeetype:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/employee/searchGrouping?search=${search}&employeetype=${employeetype}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Add Resources ---------------------------------------------------------

    createResource(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/resources/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get resources List ---------------------------------------------------------

    getResourceList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/resources/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update leads ---------------------------------------------------------

    updateResource(id: any, model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/resources/update/${id}`, model).subscribe((responseData) => {
            this.tagResponse = responseData;
            subject.next(this.tagResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    // -------------------------- Delete leads ---------------------------------------------------------
    deleteResource(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/resources/deleteResources/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    // -------------------------- Get Resource By Id ---------------------------------------------------------

    getResourceById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/resources/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }


    addSection(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/sections/create`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    updateSection(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/sections/update/${model._id}`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    deleteSection(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/sections/remove/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    addChapter(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/sections/addChapter`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    updateChapter(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/chapters/update/${model._id}`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    deleteChapter(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/chapters/remove/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    addTopic(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/chapters/addTopic`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    updateTopic(model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/topics/update/${model._id}`, model).subscribe((response: any) => {
            subject.next(response);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();

    }
    deleteTopic(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/topics/remove/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    getSectionsByCourse(id: any, studentId?: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        let params = `course=${id}`;
        if (studentId) {
            params += `&student=${studentId}`;
        }
        this.http.get(`${this.root}/sections/getByCourse?pageSize=25&pageNo=1&${params}`).subscribe(
            (res: any) => {
                subject.next(res);
            },
            (error: any) => {
                subject.next(error.error);
            }
        );

        return subject.asObservable();
    }

    getAppsByTopic(id: any, pageSize: any, pageNo: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/apps/getByTopic?pageSize=${pageSize}&pageNo=${pageNo}&topic=${id}`).subscribe((res: any) => {
            subject.next(res);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     getAppsByTopicWithoutToken(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/apps/getByTopicWithoutToken/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } getByTopicWithoutToken
    // ---------------- Checklist Create --------------------------------------------------------------

    createChecklist(data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/checklist/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Checklist update --------------------------------------------------------------

    updateChecklist(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/checklist/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get All Checklist ---------------------------------------------------------
    getAllChecklist(pageNo:any, pageSize:any,type:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/checklist/getAll?pageNo=${pageNo}&pageSize=${pageSize}&type=${type}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Checklist By Id ---------------------------------------------------------

    getChecklistById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/checklist/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Checklist ---------------------------------------------------------

    deleteChecklist(id: any, password: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/checklist/delete/${id}?password=${password}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete App ---------------------------------------------------------

    deleteApp(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/apps/remove/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add app ---------------------------------------------------------

    addApp(formData) {
        const subject = new Subject<any>();
        this.http.post(`${this.root}/apps/create`, formData, { reportProgress: true, observe: "events" }).subscribe((responseData: any) => {
            subject.next(responseData);
        }, (error) => {
            const dataModel = error;
            subject.next(dataModel);
        });
        return subject.asObservable();

    }
    // -------------------------- Update App ---------------------------------------------------------
    updateApp(id, formData) {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/apps/update/${id}`, formData, { reportProgress: true, observe: "events" }).subscribe((responseData: any) => {
            subject.next(responseData);
        }, (error) => {
            const dataModel = error;
            subject.next(dataModel);
        });
        return subject.asObservable();

    }
    // -------------------------- searchApp ---------------------------------------------------------

    searchApp(pageNo, pageSize, type, value): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/apps/search?pageSize=${pageSize}&pageNo=${pageNo}&type=${type}&value=${value}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- add/remove in topicApp ---------------------------------------------------------

    attachOrRemoveApp(path, model): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/topics/${path}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- add/remove resource App ---------------------------------------------------------

    attachOrRemoveResourceApp(model): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/apps/attachDisattachResources`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- App Get By Type   ---------------------------------------------------------
    getAppByType(pageNo, pageSize, type): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/apps/getByType?pageNo=${pageNo}&pageSize=${pageSize}&type=${type}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update App ---------------------------------------------------------

    readLmsTopic(id, model) {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/lmslogs/readTopic/${id}`, model).subscribe((responseData: any) => {
            subject.next(responseData);
        }, (error) => {
            const dataModel = error;
            subject.next(dataModel);
        });
        return subject.asObservable();

    }
    // -------------------------- Assets Create ---------------------------------------------------------
    assetsAdd(data: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.post(`${this.root}/assets/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
      // -------------------------- Create Keyword Assets --------------------------------------------------
    addKeyword(data: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.post(`${this.root}/assets/createKeyWord`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // ---------------- Assets update --------------------------------------------------------------

    updateAssets(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/assets/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Assets List ---------------------------------------------------------
    getAssetsList(pageNo: any, pageSize: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/assets/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Assets List ---------------------------------------------------------
    filterAssets(pageNo,pageSize,search: any, type: any,category:any,domain:any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/assets/search?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}&type=${type}&category=${category}&domain=${domain}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Assets By Id ---------------------------------------------------------

    getAssetsById(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/assets/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Assets ---------------------------------------------------------
    deleteAssets(id: any, password: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/assets/delete/${id}?password=${password}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create MainMenu ---------------------------------------------------------
    mainMenuCreate(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/mainmenu/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update MainMenu ---------------------------------------------------------
    mainMenuUpdate(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/mainmenu/updatemainmenuById/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete MainMenu ---------------------------------------------------------

    deleteMainMenu(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/mainmenu/delete/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags List ---------------------------------------------------------
    getMainMenuList(pageNo: any, pageSize: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/mainmenu/getmainmenu?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags Id ---------------------------------------------------------
    getMainMenuByEmployeeTypeId(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/mainmenu/getByEmployeeType/?employeeTypes=${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags Id ---------------------------------------------------------

    getMainMenuById(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/mainmenu/getmainmenuById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create SubMenu ---------------------------------------------------------
    subMenuCreate(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/submenu/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get SubMenu List ---------------------------------------------------------
    getsubMenuList(pageNo: any, pageSize: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/submenu/getsubmenu?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Tags Id ---------------------------------------------------------

    getSubMenuById(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/submenu/getsubmenuById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update SubMenu ---------------------------------------------------------
    subMenuUpdate(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/submenu/updatesubmenuById/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete SubMenu ---------------------------------------------------------

    deleteSubMenu(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/submenu/delete/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create Discussion ---------------------------------------------------------
    createDiscussion(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/discussion/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Discussion ---------------------------------------------------------
    updateDiscussion(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/discussion/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Discussion ---------------------------------------------------------

    deleteDiscussion(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/discussion/remove/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Discussion List ---------------------------------------------------------
    getDiscussionList(pageNo: any, pageSize: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/discussion/list?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Discussion List By Student ---------------------------------------------------------
     getDiscussionListByStudent(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/discussion/getdiscussionbySenderId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Discussion List By Teacher ---------------------------------------------------------
    getDiscussionListByTeacher(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/discussion/getdiscussionbyRepliedId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Discussion Id ---------------------------------------------------------
    getDiscussionById(id: any): Observable<Tag[]> {
        const subject = new Subject<Tag[]>();
        this.http.get(`${this.root}/discussion/getdiscussionbyId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- change Section Order ---------------------------------------------------------
    changeOrder(path:string,data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/${path}/changeOrder`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Send Email ---------------------------------------------------------
      sendMail(email: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/users/userLoginCredential?email=${email}`,'').subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Create Class ---------------------------------------------------------
    createClass(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/clas/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Class ---------------------------------------------------------
    updateClass(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/clas/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Class Student ---------------------------------------------
    updateClassStudent(classId,studentId ,data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/clas/updateClassStudent/${classId}/${studentId}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Class ---------------------------------------------------------

    deleteClass(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/clas/delete/${id}?password=${password}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Class List ---------------------------------------------------------
    getClassList(pageNo: any, pageSize: any,status:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/getAll?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Class Id ---------------------------------------------------------
    getClassById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Class By User Id ---------------------------------------------------------
    getClassByUserId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/getByIdUserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Class By Course Id ---------------------------------------------------------
     getTopicsByCourseId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/topicGetByCourse/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
      // -------------------------- Get Students By Batch and Course Id ---------------------------------------------------------
      getStudentByBatchCourseId(id: any,courseId:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/studentGetByBatchIdAndCourseId/${id}?courseId=${courseId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Add Students to Upload CSV file ---------------------------------------------------------
    addStudentByUploadCsv(data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/student/excelToDbStudents`,data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Add Student Attendence ---------------------------------------------------------
     addAttendance(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/studentattendance/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Student Attendance List ---------------------------------------------------------
       getStudentAttendanceList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/studentattendance/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Get Student Attendance By Student Id ---------------------------------------------------------
      getAttendanceByStudentId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/studentattendance/getByuserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
    // -------------------------- Add Opening  ---------------------------------------------------------
    createOpening(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/jobopening/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Opening ---------------------------------------------------------
    updateOpening(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/jobopening/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Opening ---------------------------------------------------------

    deleteOpening(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/jobopening/delete/${id}?password=${password}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Opening List ---------------------------------------------------------
    getOpeningList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobopening/getAll?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Opening Id ---------------------------------------------------------
    getOpeningById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobopening/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Get Opening List By Status ---------------------------------------------------------

      getOpeningListByStatus(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobopening/jobopeningetByStatus/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Filter JobOpening  ---------------------------------------------------------
     getJobOpeningFilter(pageNo: any,pageSize:any,status:any,applied_for:any,sort_listed:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/jobopening/filter?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&applied_for=${applied_for}&sort_listed=${sort_listed}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Credential  ---------------------------------------------------------
    createCredential(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/credentials/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Credential ---------------------------------------------------------
    updateCredential(id, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/credentials/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Credential ---------------------------------------------------------

    deleteCredential(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/credentials/deleteCredentials/${id}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Credential List ---------------------------------------------------------
    getCredentialList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/credentials/getAllCredentials?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Credential Id ---------------------------------------------------------
    getCredentialById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/credentials/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

     // -------------------------- Get Questions List ---------------------------------------------------------
    getQuestionsList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/question/getAllQuestion?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Add Employee Attendence ---------------------------------------------------------
     addEmpAttendance(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/attendance/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Employee Attendence By Hr ---------------------------------------------------------
    addEmpAttendanceHr(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/attendance/createHr`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Employee Attendence ---------------------------------------------------------
    addEmpWeeklyAttendance(day:any,data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/attendance/createMultiple?day=${day}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Bulk Employee Attendence ---------------------------------------------------------
    addBulkAttendance(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/attendance/createBulkAttendance`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Update Employee Attendence ---------------------------------------------------------
     updateEmpAttendance(id:any,model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/attendance/update/${id}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee Attendance List ---------------------------------------------------------
       getEmpAttendanceList(pageNo: any, pageSize: any,startDate: any, endDate: any,isVerified:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getAll?pageNo=${pageNo}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&isVerified=${isVerified}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // ---------------------- GetEmployee Attendance ListByMonth  --------------------------------------------------------
     getEmpAttendanceListByMonth(year: any, month: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/GetAttendancebyMonth?year=${year}&month=${month}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // ---------------------- check Employee Attendance --------------------------------------------------------
      checkEmpAttendance(checkDate: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getEmployeeAttendance?checkDate=${checkDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // ---------------------- GetEmployee Attendance ListByTwoDate  --------------------------------------------------------
     getEmpAttendanceListByTwoDate(startDate: any, endDate: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/GetAttendanceByTwoDates?startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------------- GetEmployee Attendance ListByTwoDate StatusWise --------------------------------------------------------
    getEmpAttendanceListByTwoDateStatusWise(startDate: any, endDate: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getAttendanceStatusWise?startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------------- GetEmployee particular Attendance filter StatusWise --------------------------------------------------------
    getCurrentEmpAttendanceFilter(id:any,startDate: any, endDate: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/getAttendanceUserAndStatusWise/${id}?startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // ---------------------- Filter for Emp Attendance  --------------------------------------------------------
     getFilterEmpAttendance(userId:any,search: any,fromDate:any,toDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/searchByQuery?userId=${userId}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------------- Filter all Emp Attendance  --------------------------------------------------------
    getFilterAllEmpAttendance(search: any,fromDate:any,toDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/attendance/searchAttendance?search=${search}&fromDate=${fromDate}&toDate=${toDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

       // -------------------------- Delete Employee Attendence ---------------------------------------------------------
       deleteEmpAttendance(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/attendance/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Add Knowledge Base  ---------------------------------------------------------
      addKnowledgeBase(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/knowledgebase/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Update Knowledge Base ---------------------------------------------------------
     updateKnowledgeBase(id:any,model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/knowledgebase/update/${id}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Employee Attendance List ---------------------------------------------------------
       getKnowledgeBaseList(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/knowledgebase/getAllknowledgebase?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- getKnowledgeBaseByTag ---------------------------------------------------------
    getKnowledgeBaseByTag(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/knowledgebase/getByTag/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- knowledgebase search ---------------------------------------------------------
     filterKnowledgeBase(search:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/knowledgebase/search?search=${search}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- getKnowledgeBaseBy Id ---------------------------------------------------------
    getKnowledgeBaseById(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/knowledgebase/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Add Event  ---------------------------------------------------------
    addEvent(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/event/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Update Event ---------------------------------------------------------
     updateEvent(id:any,model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/event/update/${id}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
      // -------------------------- Delete Approved Student from Event ---------------------------------------------------------
      removeApprovedStudentEvent(id:any,model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/event/attachdisattachApproved/${id}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- download_E_Certificate_link Event  ---------------------------------------------------------
    sendMailCertificate(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/event/download_E_Certificate_link`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    } 
     // -------------------------- Upload Image For Event ---------------------------------------------------------
     uploadCover(id:any,data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/event/eventCoverPhoto/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Update Image For Event ---------------------------------------------------------
    updateCover(id:any,data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/event/updateEventCoverPhoto/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Event List ---------------------------------------------------------
       getEventList(pageNo: any, pageSize: any,status:any,startDate:any,endDate:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/event/getAllEvent?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Filter Event List ---------------------------------------------------------
    filterEvent(serach:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/event/search?search=${serach}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Event List Open Status ---------------------------------------------------------
    getEventListStatus(pageNo: any, pageSize: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/event/getAllEventOpenStatus?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Event By ID ---------------------------------------------------------
     getEventById(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/event/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Event ---------------------------------------------------------
    deleteEvent(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/event/deleteEvent/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- All Users List -----------------------------------------------------

    getUsers(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/users/getAllUsers`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Approved Student -----------------------------------------------------

    getApprovedStudent(id:any,EvntId): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/event/getByApproved/${id}?EvntId=${EvntId}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Add Setting  ---------------------------------------------------------
     addSetting(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/settings/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Update Setting ---------------------------------------------------------
     updateSetting(id:any,model: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/settings/updatesettingsById/${id}`, model).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get All Setting -----------------------------------------------------

     getAllSetting(pageNo:any,pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/settings/getsettings?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Get Setting By Id -----------------------------------------------------

     getSettingById(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/settings/getsettingsById/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete Setting ---------------------------------------------------------
    deleteSetting(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/settings/delete/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Search Setting  -----------------------------------------------------

    searchSettings(search:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/settings/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
// ----------- Add Client -------------------------------------------
        
    addClient(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/client/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
// ----------- Update Client -------------------------------------------

    updateClient(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/client/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
// ----------- Get Client List -------------------------------------------

    getAllClients(pageNo:any,pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/client/getAllClient?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Get Client By User Id -------------------------------------------

    getClientById(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/client/getByUser/${id}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    
    // ----------- Filter Client -------------------------------------------

    filterClients(search:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/client/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Delete Client ---------------------------------------------------------
     deleteClient(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/client/deleteClient/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Add Campaign -------------------------------------------
        
    addCampaign(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/campaign/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Update Campaign -------------------------------------------

    updateCampaign(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/campaign/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Get Campaign List -------------------------------------------

    getAllCampaign(pageNo:any,pageSize:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/campaign/getAllcampaign?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Filter Campaign -------------------------------------------

    filterCampaign(search:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/campaign/search?search=${search}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // -------------------------- Delete Campaign ---------------------------------------------------------
     deleteCampaign(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/campaign/deletecampaign/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Add College -------------------------------------------
        
    addCollege(data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/college/create`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Update College -------------------------------------------

    updateCollege(id: any, data: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/college/update/${id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Get College List -------------------------------------------

    getAllCollege(pageNo:any,pageSize:any,mou:any,city:any,relationship:any,SortBy:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/college/list?pageNo=${pageNo}&pageSize=${pageSize}&mou=${mou}&city=${city}&relationship=${relationship}&SortBy=${SortBy}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Delete College ---------------------------------------------------------
    deleteCollege(id:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/college/remove/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
  // ----------- Filter College List -------------------------------------------

  filterCollege(pageNo:any,pageSize:any,search:any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.get(`${this.root}/college/search?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`).subscribe((responseData) => {
        this.userResponse = responseData;
        subject.next(this.userResponse);
    }, (error) => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// ----------- Get All Records -------------------------------------------

getAllRecords(): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.get(`${this.root}/users/getAllRecords`).subscribe((responseData) => {
        this.userResponse = responseData;
        subject.next(this.userResponse);
    }, (error) => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
  // ---------------- Checklist update sprint --------------------------------------------------------------

  updateSprintChecklist(id: any,checkId:any, data: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.put(`${this.root}/checklist/updateSprint/${id}/${checkId}`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
 // ----------- Add Sprint -------------------------------------------
        
 addSprint(data: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.post(`${this.root}/sprint/create`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ----------- Add Bulk Sprint -------------------------------------------
        
addBulkSprint(data: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.post(`${this.root}/sprintdata/bulkoperation`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- Update Sprint From Enrollment --------------------------------------------------------------

updateSprintEnroll(sprint_id: any,data: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.put(`${this.root}/sprint/update/${sprint_id}`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- checklist Update Sprint  --------------------------------------------------------------

updateChecklistSprint(sprint_id: any,DataId: any,data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.put(`${this.root}/sprint/updateSprint/${sprint_id}/${DataId}`,data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- Update Sprint Data --------------------------------------------------------------

updateSprint(sprint_id: any,DataId: any,data): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.put(`${this.root}/sprintdata/updatesprintdata/${sprint_id}/${DataId}`,data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
 // -------------------------- Get Sprint By Checklist Id ---------------------------------------------------------

 getSprintByChecklistId(checklist_id: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.get(`${this.root}/checklist/checklistGetBySprint/${checklist_id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// -------------------------- Get All referral  ---------------------------------------------------------

getAllReferrals(): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.get(`${this.root}/users/getAllRefrenceChain`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All referral  ---------------------------------------------------------

getAllReferralsByStatus(status:any,refStatus:any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.get(`${this.root}/users/getAllRefrenceChain?status=${status}&Refstatus=${refStatus}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

 // ----------- Send Login Otp  -------------------------------------------
        
 sendLoginOtp(data: any): Observable<any[]> {
    const subject = new Subject<any[]>();
    this.http.post(`${this.root}/users/loginOtp`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// ----------- Send Login Otp  -------------------------------------------
        
copyEvent(id: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/event/createDuplicate/${id}`,).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ----------- Assignment Create   -------------------------------------------

createAssignment(data: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.post(`${this.root}/assignment/create`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- Update Assignment --------------------------------------------------------------

updateAssignment(id: any,data:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.put(`${this.root}/assignment/update/${id}`,data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// -------------------------- Get All Assignment  ---------------------------------------------------------

getAllAssignment(pageNo:any,pageSize:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/getAllAssignment?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get Assignment By Student  ---------------------------------------------------------

getAssignmentByStudent(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/assignmentGetByStudentId/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Filter Assignment  ---------------------------------------------------------

filterAssignment(search:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/search?search=${search}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Check Assignment Submit By Student  ---------------------------------------------------------

checkAssignmentSubmitByStudent(stuId:any,topicId:any,appId:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/requestSubmit/${stuId}/${topicId}/${appId}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// --------------------------  Assignment Get By Teacher ---------------------------------------------------------

getAssignmentByTeacher(empId:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/assignmentGetByTeacher/${empId}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ----------- Referal Create   -------------------------------------------

addReferal(data: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.post(`${this.root}/referal/create`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- Update Referal --------------------------------------------------------------

updateReferal(id: any,data:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.put(`${this.root}/referal/update/${id}`,data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All Referal  ---------------------------------------------------------

getAllReferal(pageNo:any,pageSize:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/referal/getAllReferal?pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get Referal By Id  ---------------------------------------------------------

getReferalById(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/referal/getById/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// -------------------------- Get Referal By User  ---------------------------------------------------------

getReferalByUser(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/referal/getUserReferal/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
} 
// -------------------------- Filter Referal   ---------------------------------------------------------

filterReferal(search:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/users/getAllRefrenceChainSearch?search=${search}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
} 
// -------------------------- Get All FAQ of course---------------------------------------------------------

getAllFaqByCourse(id:any,name:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/projectGetByCourse/${id}?name=${name}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
getAllCourses(page:any,size:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/course/getcourses?pageNo=${page}&pageSize=${size}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All Project of Student ---------------------------------------------------------
getAllProjectByStudent(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/projectGetByUser/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All Resources of course---------------------------------------------------------

getAllResourcesStudentByCourse(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/assignment/resourceGetByStudent/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}

// -------------------------- Role Change Student to Employee ---------------------------------------------------------
updateUserRole(id: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.put(`${this.root}/users/changeRole/${id}`,'').subscribe((responseData) => {
        this.tagResponse = responseData;
        subject.next(this.tagResponse);
    }, (error) => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get Default Course ---------------------------------------------------------

getDefaultCourse(): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/course/getTwoCourses`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ----------- Onboarding Create   -------------------------------------------

addOnboarding(data: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.post(`${this.root}/onboarding/create`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ---------------- Update Onboarding --------------------------------------------------------------

updateOnboarding(id: any,data:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.put(`${this.root}/onboarding/update/${id}`,data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All Onboarding  ---------------------------------------------------------

getAllOnboarding(status:any,pageNo:any,pageSize:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/onboarding/getonboarding?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get All Onboarding By Student  ---------------------------------------------------------

getAllOnboardingByStudent(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/onboarding/getByStudent/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Get Onboarding By Id  ---------------------------------------------------------

getOnboardingById(id:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/onboarding/getById/${id}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Filter Onboarding  ---------------------------------------------------------

getFilterOnboarding(search:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/onboarding/search?search=${search}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Course Reports  ---------------------------------------------------------

getCourseReports(startDate:any,endDate:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/enrolments/studentCourseMonthWise?startDate=${startDate}&endDate=${endDate}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Course Reports Month Year Wise  ---------------------------------------------------------

getCourseReportsMonthYear(month:any,year:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/enrolments/studentCourseMonthYearWise?month=${month}&year=${year}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Delete old Outsource Leads  ---------------------------------------------------------

deleteOldOutsourceLeads(password:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.get(`${this.root}/outsourcingLeads/deletecloseAlloutsourcingLeads?password=${password}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Delete old Employee  ---------------------------------------------------------

deleteOldEmployee(password:any): Observable<any> {
    const subject = new Subject<any>();
    this.http.delete(`${this.root}/employee/deleteThreeYearOldEmp?password=${password}`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// -------------------------- Delete Old Training Lead  ---------------------------------------------------------

deleteOldTrainingLead(): Observable<any> {
    const subject = new Subject<any>();
    this.http.delete(`${this.root}/trainingLeads/removeOneYearOldTrainingLead`).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
}
// ----------- Add Class Template -------------------------------------------

addClassTemplate(data: any): Observable<any> {
    const subject = new Subject<any>();
    this.http.post(`${this.root}/classTemplate/create`, data).subscribe(res => {
        this.userResponse = res;
        subject.next(this.userResponse);
    }, error => {
        subject.next(error.error);
    });
    return subject.asObservable();
    }
    // ----------- Update Class Template -------------------------------------------

    updateClassTemplate(data: any): Observable<any> {
        const subject = new Subject<any>();
        this.http.put(`${this.root}/classTemplate/update/${data._id}`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Get Class Template By Id -------------------------------------------
    getClassTemplate(id:any): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/getById/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Get All Class Template -------------------------------------------
    getAllClassTemplate(): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/getAll`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    
    // ----------- Get Class Templates by course -------------------------------------------
    getclassTemplatesByCourseId(page,size,id): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/topicGetByCourse/${id}?pageNo=${page}&pageSize=${size}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ---------------- Delete Class Template ---------------------------------------------------------

    deleteClassTemplate(id: any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/classTemplate/deletePermanent/${id}?password=${password}`).subscribe((res: any) => {
            subject.next(res);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    
    // --------------------------  change ClassTemplateOrder ---------------------------------------------------------
    changeClassTemplateOrder(data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/classTemplate/changeOrder`, data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Check MultiConnect -------------------------------------------
    checkMultiConnect(id): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/checkMultiConnect/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ----------- Check Not Connect -------------------------------------------
    checkNotConnect(id): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/checkNotConnect/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // ----------- Get All Sessions -------------------------------------------
    getAllSessions(page,size): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/classTemplate/getAll?pageNo=${page}&pageSize=${size}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // --------- Create Student Attendence -----------
    // createStuAttByTeacherWithClassId
    createStudentAttendence(id): Observable<any> {
        const subject = new Subject<any>();
        this.http.get(`${this.root}/clas/createStuAttByTeacherWithClassId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Get Student By User Id ---------------------------------------------------------
    getStudentAttendenceByUserId(id: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/studentattendance/getByuserId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ------------ Attendence Mark Student By User Id ---------------------------------------------------------
    addAttendence(classId:any,studentId: any,feedbackForm: any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/clas/updateStuAttByStudentIdWithClassId/${classId}/${studentId}`,feedbackForm).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }

    //------- Get Pending Topic By User Id --------------
     getPendingTopicByUserId(courseId: any,studentId:any,teacher:any,student:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/studentattendance/checkPendingTopics/${courseId}?studentId=${studentId}&teacher=${teacher}&student=${student}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     //------- Get Batch and students by class Id --------------
     getBatchStudentByClassId(classId:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/clas/getAllBatchStudentByClasId/${classId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     //------- Get Session by Course Id --------------
     getSessionsByCourseId(courseId:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/classTemplate/getByIdCourse/${courseId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Session's Topic by Session Id --------------
     updateTopicsInClass(classId:any,batchId:any,data): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.put(`${this.root}/clas/updateAllStuTopicByClassId/${classId}/${batchId}`,data).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Delete Student from Class --------------
    deleteStudentFromClass(student: any, classId:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/clas/deleteClassStudent/${student}/${classId}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // -------------------------- Users List -----------------------------------------------------

    studentsGetByTeacher(teacherId,status,pageNo, pageSize): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/enrolments/studentsGetByTeacher/${teacherId}?&learning_mode=${status}&pageNo=${pageNo}&pageSize=${pageSize}`).subscribe((responseData) => {
            this.userResponse = responseData;
            subject.next(this.userResponse);
        }, (error) => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ------------ Add Payment Reports ---------------------------------------------------------
    addPaymentReports(modal): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/outsourcingtransaction/createTransaction`,modal).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports --------------
    getPaymentReports(startDate:any,endDate:any,channel:any,farming:any,serach:any,type:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/getAlltransactions?startDate=${startDate}&endDate=${endDate}&channel=${channel}&farming=${farming}&serach=${serach}&type=${type}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports Current Month Transactions --------------
    getPaymentReportsCurrentMonthTransactions(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/currentMonthTransactions`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports Previous Month Transactions --------------
    getPaymentReportsPreviousMonthTransactions(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/previousMonthTransactions`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports Current Week Transactions --------------
    getPaymentReportsCurrentWeekTransactions(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/currentWeekTransactions`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports Previous Week Transactions --------------
    getPaymentReportsPreviousWeekTransactions(): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/previousWeekTransactions`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Fixed Payment Reports --------------
    getFixedPaymentReports(startDate:any,endDate:any,serach:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/getAllFixetTransactions?startDate=${startDate}&endDate=${endDate}&serach=${serach}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get Payment Reports By Job Id --------------
     getPaymentReportsById(id): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/outsourcingtransaction/TransactionByJobId/${id}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
     // ------------ Add Lead Dump ---------------------------------------------------------
    addLeadDump(modal): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.post(`${this.root}/leadDump/add`,modal).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    //------- Get All Dump Data --------------
     getAllDumpData(pageNo:any,pageSize:any,month:any,year:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/leadDump/getAllleadsDump?pageNo=${pageNo}&pageSize=${pageSize}&month=${month}&year=${year}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    // ------------- Delete All Training Lead Dump Data -----------------------------------------
     deleteAllDumpData(FromDate:any,toDate:any,password:any): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.delete(`${this.root}/leadDump/removeByDate?FromDate=${FromDate}&toDate=${toDate}&password=${password}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
    search_sections_chapters_topics(id: string, text: string): Observable<any[]> {
        const subject = new Subject<any[]>();
        this.http.get(`${this.root}/sections/getByCourseAndText?course=${id}&name=${text}`).subscribe(res => {
            this.userResponse = res;
            subject.next(this.userResponse);
        }, error => {
            subject.next(error.error);
        });
        return subject.asObservable();
    }
}

