<div class="modal-header">
  <h4 class="modal-title">{{ userName | titlecase}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Basic Inputs start -->
  <section id="basic-input">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- <div class="card-header">
                      <h4 class="card-title">Basic</h4>
                  </div> -->
          <div class="card-content">
            <div class="card-body">
              <div class="row">
                <div class="col-md">
                  <form>
                    <fieldset class="form-group">
                      <label>Amount</label>
                      <input type="text" class="form-control" [(ngModel)]="transactionModel.paid_amount" [ngModelOptions]="{ standalone: true }">
                    </fieldset>
                    <fieldset class="form-group">
                      <label class="form-control-label" for="status"
                      >Payment Status</label
                    >
                    <ng-select
                      [items]="pamentStatusList"
                      bindLabel="label"
                      bindValue="name"
                      placeholder="Select Status"
                      [(ngModel)]="selectedPaymentStatus"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                    </fieldset>
                    <fieldset class="form-group">
                      <label class="form-control-label" for="status"
                        >Payment Mode</label
                      >
                      <ng-select
                        [items]="paymentMode"
                        bindLabel="displayname"
                        bindValue="_id"
                        placeholder="Select Payment Mode"
                        [(ngModel)]="selectedPaymentMode"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="getPaymentModeId($event)"
                      >
                      </ng-select>
                  </fieldset>
                    <fieldset class="form-group">
                      <label for="next_payment_date">Next payment date</label>
                      <input
                        type="date"
                        class="form-control"
                        [(ngModel)]="transactionModel.next_payment_date"
                        ngModel
                        #next_payment_date="ngModel"
                        min="{{ min_next_payment_date }}"
                        name="next_payment_date"
                        id="next_payment_date"
                      />
                    </fieldset>
                  </form>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal-footer">
  <button
    type="submit"
    class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
    (click)="paymentSubmit()"
  >
    Submit
  </button>
</div>
