import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { RoleService } from '../services/role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private store: LocalStorageService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.userValue;
    const token = this.store.getItem('token');
    if (currentUser && token) {
      return true;
    }
    this.router.navigate(['/landing'], { queryParams: { 'next': state.url } });
    return false;
  }
}
@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private roleService: RoleService, private router: Router,private authService: AuthService,
    private store: LocalStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const requiredRoles = next.data.roles
    const currentUser = this.authService.userValue;
    const token = this.store.getItem('token');
    if(currentUser && token ){
      if (this.roleService.hasRole(requiredRoles)) {
        return true;
      } else {
        // Redirect to a forbidden page or handle unauthorized access
        this.router.navigate(['/dashboard']);
        return false;
      }
    }
    if(!currentUser && !token){
      this.router.navigate(['/login'], { queryParams: { 'next': state.url } });
      return false;
    }
    
   
  }
}
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  // here you can inject your auth service to check that user is signed in or not
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.userValue) {
      if (this.authService.userValue.roles === 'student') {
        this.router.navigate(['/home/dashboard']);
        return false;
      }
      else if (this.authService.userValue.roles === 'admin' || this.authService.userValue.roles === 'employee' || this.authService.userValue.roles == 'extractor') {
        this.router.navigate(['/dashboard']);
        return false;
      }
      // this.router.navigate([""]); // or home
    }
    return true;
  }
}
