import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
    transform(value: string): string {
		// Parse the input date string into a Date object
		const date = new Date(value);
	
		// Define the month names
		const monthNames = [
		  'Jan', 'Feb', 'Mar', 'Apr',
		  'May', 'Jun', 'Jul', 'Aug',
		  'Sep', 'Oct', 'Nov', 'Dec'
		];
	
		// Get the month, day, and year from the Date object
		const month = monthNames[date.getUTCMonth()];
		const day = date.getUTCDate();
		const year = date.getUTCFullYear();
	
		// Format the date in the desired format
		return `${month} ${day} ${year}`;
	  }
	}