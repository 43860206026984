<div class="modal-header">
  <h4 class="modal-title" *ngIf="isBatch">Batch Form</h4>
  <h4 class="modal-title" *ngIf="isCollege">College Form</h4>
  <h4 class="modal-title" *ngIf="isEnrollmentPopup">Transaction</h4>
  <h4 class="modal-title" *ngIf="isTransactionUpdate">Transaction Update</h4>
  <!-- <h4 class="modal-title" *ngIf="isEnrollmentPopup || isTransaction"></h4> -->

  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- Basic Inputs start -->
<!------------------------------ Add New Batch Popup ------------------------------------------------------------------------->

  <section id="basic-input" *ngIf="isBatch">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="row">
                <div class="col-md">
                  <form [formGroup]="tagsForm">
                    <fieldset class="form-group">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="name"
                        id="name"
                        placeholder="Enter name"
                        [ngClass]="{
                          'is-invalid': tagsFormSubmitted && rf.name.invalid,
                          'is-valid': tagsFormSubmitted && !rf.name.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="
                          rf.name.touched &&
                          (rf.name.invalid || rf.name.errors?.required)
                        "
                        class="help-block mt-1 text-danger"
                      >
                        <i class="ft-alert-circle align-middle"></i> Name is
                        required
                      </div>
                    </fieldset>
                    <fieldset class="form-group">
                      <label for="displayname">Display Name</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="displayname"
                        id="displayname"
                        placeholder="Enter displayname"
                        [ngClass]="{
                          'is-invalid':
                            tagsFormSubmitted && rf.displayname.invalid,
                          'is-valid':
                            tagsFormSubmitted && !rf.displayname.invalid
                        }"
                        required
                      />
                      <div
                        *ngIf="
                          rf.displayname.touched &&
                          (rf.displayname.invalid ||
                            rf.displayname.errors?.required)
                        "
                        class="help-block mt-1 text-danger"
                      >
                        <i class="ft-alert-circle align-middle"></i> display
                        name is required
                      </div>
                    </fieldset>
                    <fieldset class="form-group">                            
                          <ng-select
                          [items]="categories"
                          bindLabel="name"
                          bindValue="_id"
                          [multiple]="true"
                          [closeOnSelect]="false"
                          placeholder="Select Tag Type"
                          [(ngModel)]="selectedValue"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="getCategoryId($event)"
                        >
                        </ng-select>
                      </fieldset>
                    <fieldset class="form-group mt-3">
                      <label for="description">description</label>
                      <textarea
                        formControlName="description"
                        id="hidden-label-form-6"
                        rows="3"
                        class="form-control"
                        name="comment2"
                        placeholder="Enter description"
                        required
                        #description
                      ></textarea>
                      <!-- <div *ngIf="rf.description.touched && (rf.description.invalid ||
                                rf.description.errors?.required)"
                                class="help-block mt-1 text-danger"> <i class="ft-alert-circle
                                    align-middle"></i> description is required
                            </div> -->
                    </fieldset>
                  </form>
                </div>
                <div class="col-md">
                  <form [formGroup]="tagsForm">
                    <fieldset class="form-group">
                      <label for="notes">notes</label>
                      <textarea
                        formControlName="notes"
                        id="hidden-label-form-6"
                        rows="4"
                        class="form-control"
                        name="comment2"
                        placeholder="Enter notes"
                        required
                        #notes
                      ></textarea>
                      <!-- <div *ngIf="rf.notes.touched && (rf.notes.invalid ||
                                rf.notes.errors?.required)"
                                class="help-block mt-1 text-danger"> <i class="ft-alert-circle
                                    align-middle"></i> notes is required
                            </div> -->
                    </fieldset>
                    <fieldset class="form-group">
                      <label for="admin_notes">admin notes</label>
                      <textarea
                        formControlName="admin_notes"
                        id="hidden-label-form-6"
                        rows="4"
                        class="form-control"
                        name="comment2"
                        placeholder="Enter admin notes"
                        required
                        #admin_notes
                      ></textarea>
                      <!-- <div *ngIf="rf.admin_notes.touched && (rf.admin_notes.invalid ||
                                    rf.admin_notes.errors?.required)"
                                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle
                                        align-middle"></i> admin notes is required
                                </div> -->
                    </fieldset>
                    <!-- <fieldset class="form-group" >
                            <input type="file" class="form-control" formControlName="image"											
                                required (change)="onSelectFile($event)">
                            
                        </fieldset> -->
                    <!-- <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="submit()">Submit</button>
                            <button type="reset" class="btn btn-secondary" (click)="back()">Cancel</button>
                          </div> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!------------------------------ Add New College Popup ------------------------------------------------------------------------->

  <div *ngIf="isCollege">
    <section id="basic-input">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <form [formGroup]="businessForm">
                        <fieldset class="form-group">
                          <label for="name">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="name"
                            id="name"
                            placeholder="Enter college name"
                            [ngClass]="{
                              'is-invalid':
                                businessFormSubmitted && rff.name.invalid,
                              'is-valid':
                                businessFormSubmitted && !rff.name.invalid
                            }"
                            required
                          #businessName/>
                          <div
                            *ngIf="
                            rff.name.touched &&
                              (rff.name.invalid ||
                                rff.name.errors?.required)
                            "
                            class="help-block mt-1 text-danger"
                          >
                            <i class="ft-alert-circle align-middle"></i>Name
                            is required
                          </div>
                        </fieldset>
                        <!-- <fieldset class="form-group">
                          <label for="name">Website</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="website"
                            id="website"
                            placeholder="Enter Website"
                            required
                         #website />
                      
                        </fieldset>
                        <fieldset class="form-group">
                          <label for="name">Descriptions</label>
                          <textarea
                            id="hidden-label-form-6"
                            rows="5"
                            class="form-control"
                            formControlName="description"
                            id="name"
                            placeholder="Enter  Descriptions"
                            name="comment2"
                            required
                         #description ></textarea>
                       
                        </fieldset>
                      </form>
                    </div>
                    <div class="col-md-6">
                      <form [formGroup]="businessForm">
                        <fieldset class="form-group">
                          <label for="name">Phone Number</label>
                          <input
                            type="text" 
                            placeholder="e.g. (555) 555 5555" mask="(000) 000-0000" minlength="10"
                            class="form-control"
                            formControlName="phoneNo"
                            id="phoneNo"
                            required
                         #phoneNo />
                        </fieldset>
                        <fieldset class="form-group">
                          <label for="name">Address</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="address"
                            id="address"
                            placeholder="Enter address"
                            required
                          #address/>
                         
                        </fieldset>
                        <fieldset class="form-group">
                          <label for="name">Notes</label>
                          <textarea
                            id="hidden-label-form-6"
                            rows="5"
                            class="form-control"
                            formControlName="notes"
                            id="name"
                            placeholder="Enter  notes"
                            name="comment2"
                            required
                          #notes></textarea>
                     
                        </fieldset> -->
                      
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
<!------------------------------ Enrolment Status Update ------------------------------------------------------------------------->

  <section id="basic-input" *ngIf="isEnrolment_completedCourse && enrolementStatus_Id">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="row">
            <div class="col-md">
                     <div *ngIf="!isShow">
                    <fieldset class="form-group" >
                      <h1 style="text-align: center; margin-bottom: 10px;">Are You Sure?</h1>
                      <!-- <p style="text-align: center;"> Is your Course Complete?</p> -->
                    </fieldset>
                     </div>
                  <form  *ngIf="isShow">
                    <fieldset class="form-group"  >
                      <div class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0">
                        <input type="checkbox"
                        class="custom-control-input"
                          id="color-switch-1" (change)="disableUser(enrolmentData?._id)">
                        <label class="custom-control-label mr-1" for="color-switch-1">
                          <span>You want to Disable this student from Student ?</span>
                        </label>
                      </div>
                    </fieldset>
                    <fieldset class="form-group">
                      <label>Notes</label>
                      <textarea class="form-control" [(ngModel)]="enrolmentData.notes" [ngModelOptions]="{ standalone: true }"> </textarea>
                   </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!------------------------------- Enrolment Completed Course -------------------------------------------------------->
<section id="basic-input" *ngIf="isEnrolmentStatus && !isEnrolment_completedCourse">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="row">
          <div class="col-md">
                   <div *ngIf="!isShow">
                  <fieldset class="form-group" >
                    <h1 style="text-align: center; margin-bottom: 10px;">Are You Sure?</h1>
                    <!-- <p style="text-align: center;"> Do you want to Disable this student.</p> -->
                  </fieldset>
                   </div>
                <form  *ngIf="isShow">
                  <fieldset class="form-group">
                    <div class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0">
                      <input type="checkbox"
                      class="custom-control-input"
                        id="color-switch-1" (change)="disableUser(enrolmentData?._id)">
                      <label class="custom-control-label mr-1" for="color-switch-1">
                        <span>You want to Disable this student from Student ?</span>
                      </label>
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <label>Notes</label>
                    <textarea class="form-control" [(ngModel)]="enrolmentData.notes" [ngModelOptions]="{ standalone: true }"> </textarea>
                 </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!------------------------------ Create New Payment  ------------------------------------------------------------------------->

  <section id="basic-input" *ngIf="isEnrollmentPopup">
    <div class="row">
      <div class="col-12">
                <table>
                  <tbody *ngIf="transactionData">
                    <tr>
                      <th>Net Fees :</th>
                      <td>
                        <span *ngIf="transactionData?.net_amount">{{transactionData?.net_amount}}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Paid Fees :</th>
                      <td class="bg-light-success">
                        <span *ngIf="transactionData?.total_paid">{{transactionData?.total_paid}}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Pending Fees :</th>
                      <td class="bg-light-danger">
                        <span *ngIf="transactionData?.pendingAmount">{{transactionData?.pendingAmount}}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Mode :</th>
                      <td>{{transactionData?.mode?.displayname}}</td>
                    </tr>
                    <tr *ngIf="enrolmentData?.net_fees !== enrolmentData?.paid_fee">
                      <th>Next Payment Date :</th>
                      <td>{{enrolmentData?.next_payment_date | date :'mediumDate'}}</td>
                    </tr>                      
                  </tbody>
                 
                </table>
      </div>
    </div>
  </section>
  <section id="basic-input" *ngIf="isEnrollmentPopup">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="row">
                <div class="col-md">
                  <form >
                    <fieldset class="form-group">
                      <label>Amount</label>
                      <input type="text" class="form-control" [(ngModel)]="enrolmentModel.paid_amount" [ngModelOptions]="{ standalone: true }">
                    </fieldset>
                    <fieldset class="form-group">
                        <label class="form-control-label" for="status"
                          >Payment Mode</label
                        >
                        <ng-select
                          [items]="paymentMode"
                          bindLabel="displayname"
                          bindValue="_id"
                          placeholder="Select Payment Mode"
                          [(ngModel)]="selectedPaymentMode"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="getPaymentModeId($event)"
                        >
                        </ng-select>
                    </fieldset>
                    <fieldset class="form-group">
                      <label class="form-control-label" for="status"
                      >Payment Status</label
                    >
                    <ng-select
                      [items]="pamentStatusList"
                      bindLabel="label"
                      bindValue="name"
                      placeholder="Select Status"
                      [(ngModel)]="selectedPaymentStatus"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                    </fieldset>
                    <fieldset class="form-group">
                      <label for="next_payment_date">Next payment date</label>
                      <input
                        type="date"
                        class="form-control"
                        [(ngModel)]="enrolmentModel.next_payment_date"
                        ngModel
                        #next_payment_date="ngModel"
                        min="{{ min_next_payment_date }}"
                        name="next_payment_date"
                        id="next_payment_date"
                      />
                    </fieldset>
                    <!-- <fieldset class="form-group">
                      <label class="form-control-label">Payment Notes</label>
                      <textarea class="form-control"  [(ngModel)]="selectedPaymentNotes"
                      [ngModelOptions]="{ standalone: true }" cols="30" rows="2"></textarea>
                    </fieldset> -->
                  </form>
                  <div *ngIf="transactionData?.pendingAmount == 0">
                   <span [ngClass]="{'bg-light-danger':transactionData?.pendingAmount == 0}">Student don't have pending fee!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<!------------------- Payment Update ------------------------------------------------------------------------->
  <section id="basic-input" *ngIf="isTransactionUpdate">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="row">
                <div class="col-md">
                  <form>
                    <fieldset class="form-group">
                      <label>Paid Amount</label>
                      <input type="text" class="form-control" [(ngModel)]="selectPaidAmount" [ngModelOptions]="{ standalone: true }">
                    </fieldset>
                    <fieldset class="form-group">
                        <label class="form-control-label" for="status"
                          >Payment Mode</label
                        >
                        <ng-select
                          [items]="paymentMode"
                          bindLabel="displayname"
                          bindValue="_id"
                          placeholder="Select Payment Mode"
                          [(ngModel)]="selectedPaymentMode"
                          [ngModelOptions]="{ standalone: true }"
                          (change)="getPaymentModeId($event)"
                        >
                        </ng-select>
                    </fieldset>
                    <fieldset class="form-group">
                      <label class="form-control-label" for="status"
                      >Payment Status</label
                    >
                    <ng-select
                      [items]="pamentStatusList"
                      bindLabel="label"
                      bindValue="name"
                      placeholder="Select Status"
                      [(ngModel)]="selectedPaymentStatus"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select>
                    </fieldset>
                    <fieldset class="form-group">
                      <label for="next_payment_date">Next payment date</label>
                      <input
                        type="date"
                        class="form-control"
                        [(ngModel)]="selectedPaymentNotes"
                        ngModel
                        #next_payment_date="ngModel"
                        min="{{ min_next_payment_date }}"
                        name="next_payment_date"
                        id="next_payment_date"
                      />
                    </fieldset>
                    <!-- <fieldset class="form-group">
                      <label class="form-control-label">Payment Notes</label>
                      <textarea class="form-control"  [(ngModel)]="selectedPaymentNotes"
                      [ngModelOptions]="{ standalone: true }" cols="30" rows="2"></textarea>
                    </fieldset> -->
                  </form>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<!------------------- End Payment Update ------------------------------------------------------------------------->
</div>
<div class="modal-footer">
<!------------------- Button's for Enrolment Status update ------------------------------------------------------------------------->
  
    <button *ngIf="!isShow && !isEnrollmentPopup && !isTransactionUpdate && !isDueFees"  type="submit" (click)="show()"  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
     >Yes </button>
    <button *ngIf="!isShow && !isEnrollmentPopup && !isDueFees"
      type="submit"class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"(click)="close()">
      Cancel
    </button>    
    <div *ngIf="isShow " >
      <button   type="submit"  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
      (click)="submitNotes()" >  Submit </button>
    </div> 
<!--------------------- Button's for Batch ------------------------------------------------>
    <button *ngIf="isBatch"
      type="submit"
      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
      (click)="submit()"
    >
      Submit
    </button>
<!--------------------- Button's for College ------------------------------------------------>

    <button *ngIf="isCollege"
    type="submit" 
    class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
    (click)="submitBusiness()">
    Submit
  </button>
<!-- <button (click)="transaction()" *ngIf="!isPayButton && isEnrollmentPopup && enrolmentData?.net_fees !== enrolmentData?.paid_fee" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">
  Pay
</button> -->

<!--------------------- Button's for Add Payment  ------------------------------------------------>

  <button [disabled]="!enrolmentModel.paid_amount" (click)="paymentSubmit()" *ngIf="transactionData?.pendingAmount !== 0 && isEnrollmentPopup"  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">
    Submit
  </button>
<!--------------------- Button's for Update Payment  ------------------------------------------------>

  <button (click)="paymentupdate()" *ngIf="isTransactionUpdate"  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">
    Submit
  </button>
  <button *ngIf="isEnrollmentPopup && isTransactionUpdate" type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
   (click)="close()">
   Cancel
  </button>

</div>
